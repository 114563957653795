import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

interface LoaderProps {
  message?: string; // here msg is optional
  color?:
    | "success"
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning";
}

export default function Loader({ message, color = "success" }: LoaderProps) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column",padding: "10px 20px" }}
    >
      <CircularProgress color={color} />
      {message && (
        <Typography
          variant="h6"
          color={color}
          sx={{ textAlign: "center", marginTop: 1 }} // Ensures text is centered and spaced properly
        >
          {message}
        </Typography>
      )}
    </Box>
  );
}
