import {
  Grid,
  IconButton,
  Typography,
  Modal,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { isArrayWithLength } from "../utils/helper";
import {
  inventoryItemColDef,
  inventoryLocationsColDef,
  inventoryMovementsColDef,
  inventoryOrdersColDef,
} from "../constants/columnDefs";
import {
  getFilteredInventoryLocations,
  getInventoryItems,
  getInventoryLocations,
  getInventoryMovementsByPagination,
  getInventoryOrder,
} from "../services/inventory.service";
import { generateOptions } from "src/utils/helper";
import InventoryItem from "./InventoryItem";
import InventoryLocation from "./InventoryLocation";
import InventoryMovement from "./InventoryMovement";
import InventoryOrder from "./InventoryOrder";

import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from "ag-grid-community";
import InventoryReport from "./InventoryReport";
import { getExcelData } from "./Dashboard/utils";
import {
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import dayjs from "dayjs";

interface MovementProps {
  rowData: any;
  columnDef: any;
  setSelectedModalData?: any;
  setMovementOpenModal?: any;
  setLocationModal?: any;
  setinventoryMovements?: any;
  deviceOptions?: any;
}

export const AgGridComponent: React.FC<MovementProps> = ({
  rowData,
  columnDef,
  setSelectedModalData,
  setMovementOpenModal,
  setLocationModal,
  setinventoryMovements,
  deviceOptions,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [skip, setSkip] = useState<number>(0);
  const [columnFilter, setColumnFilters] = useState<any>();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);
  const gridOptions: GridOptions = {
    columnDefs: columnDef,
    rowData: rowData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setSelectedModalData(e.data);
      setMovementOpenModal(true);
      if (setLocationModal) {
        setLocationModal(false);
      }
    },
  };
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
    console.log("🚀 ~ handleColumnSort ~ sortedCol:", sortedCol);
  };

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    const whereClause: any = { and: [] };

    if (Object.keys(filterModel).length > 0) {
      Object.entries(filterModel).forEach(async ([column, filter]) => {
        const { type, filterType, filter: filterValue, dateFrom } = filter;
        let filterCondition: any = {};
        switch (filterType) {
          case "text":
            if (column === "deviceName") {
             
              const isRentalSearch = filterValue.toLowerCase().includes('rental');
              let matchedValue = null, filteredDeviceOptions;
              if (isRentalSearch) {
                 filteredDeviceOptions = deviceOptions.filter((item:any) => item.label.toLowerCase().includes("rental"))
              } else {
                filteredDeviceOptions = deviceOptions.filter((item:any) => !item.label.toLowerCase().includes("rental"));        
              }
              const baseNameMatch = filteredDeviceOptions.find((item:any) => item.label.toLowerCase().includes(filterValue.toLowerCase()));
              matchedValue =  baseNameMatch ? baseNameMatch.value : null;
              filterCondition["inventoryItemId"] = matchedValue|| filterValue;
            }
            else if(["fromLocation", "toLocation"].includes(column)){
              filterCondition[column==="fromLocation"?"fromInventoryLocationId":"toInventoryLocationId"] = filterValue;
            }
            else {
              filterCondition[column] = {
                like: `.*${filterValue}.*`,
                options: "i",
              };
            }
            break;
          case "number":
            switch (type) {
              case "equals":
                filterCondition[column] = filterValue;
                break;
              case "notEqual":
                filterCondition[column] = { neq: filterValue };
                break;
              case "lessThan":
                filterCondition[column] = { lt: filterValue };
                break;
              case "lessThanOrEqual":
                filterCondition[column] = { lte: filterValue };
                break;
              case "greaterThan":
                filterCondition[column] = { gt: filterValue };
                break;
              case "greaterThanOrEqual":
                filterCondition[column] = { gte: filterValue };
                break;
              default:
                break;
            }
            break;
          case "date":
            switch (type) {
              case "equals":
                filterCondition[column] = {
                  lte: dayjs(dateFrom).add(1, "d").toISOString(),
                };
            }
            break;
          default:
            break;
        }
        whereClause.and.push(filterCondition);
      });
    }
    setColumnFilters(whereClause.and);
  };

  useEffect(() => {
    setSkip(0);
    getInventoryMovementsByPagination(0, columnFilter).then((res: any) => {
      setinventoryMovements(
        res?.map((mov: any) => ({
          ...mov,
          deviceName: mov?.inventoryItem?.name,
          fromLocation: mov?.fromInventoryLocation?.name,
          toLocation: mov?.toInventoryLocation?.name,
        }))
      );
    });
  }, [columnFilter]);

  useEffect(() => {
    getInventoryMovementsByPagination(skip, columnFilter).then((res: any) => {
      setinventoryMovements(
        res?.map((mov: any) => ({
          ...mov,
          deviceName: mov?.inventoryItem?.name,
          fromLocation: mov?.fromInventoryLocation?.name,
          toLocation: mov?.toInventoryLocation?.name,
        }))
      );
    });
  }, [skip]);

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  const onBtNext = () => {
    setSkip(skip + 30);
  };

  const onBtPrevious = () => {
    setSkip(skip - 30);
  };

  const onPaginationChanged = useCallback(() => {}, []);
  return (
    <div style={gridStyle} className="ag-theme-alpine">
      <Grid p={3}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDef}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          animateRows={true}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          domLayout="autoHeight"
          onPaginationChanged={onPaginationChanged}
          onSortChanged={handleColumnSort}
          onFilterChanged={handleColumnFilter}
        ></AgGridReact>
        <Grid
          container
          py={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
          border={1}
          borderColor={"silver"}
          bottom={0}
          left={0}
          right={0}
        >
          <Button onClick={onBtPrevious} disabled={skip === 0}>
            <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
          </Button>

          <Typography>Page {Math.round(skip / 30) + 1}</Typography>

          <Button disabled={rowData<15}  onClick={onBtNext} id="btNext">
            <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const Inventory: React.FC = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [inventoryData, setinventoryData] = useState([]);
  const [inventoryLocations, setinventoryLocations] = useState([]);
  const [inventoryMovements, setinventoryMovements] = useState([]);
  const [inventoryOrders, setinventoryOrders] = useState([]);
  const [ItemOpenModal, setItemOpenModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState<any>(null);
  const [LocationOpenModal, setLocationOpenModal] = useState(false);
  const [MovementOpenModal, setMovementOpenModal] = useState(false);
  const [locationOrderModal, setLocationOrderModal] = useState(false);
  const [locationOrder, setLocationOrder] = useState(false);
  const [locationDevice, setLocationDevice] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<any>([]);
  const [channelPartner, setChannelPartner] = useState(false);
  const [totalStocks, setTotalStocks] = useState<any>();
  const [totalOrders, setTotalOrders] = useState<any>();
  const [deviceOptions, setdeviceOptions] = useState<any>();

  const [value, setValue] = useState(0);
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const itemGridOptions: GridOptions = {
    columnDefs: inventoryItemColDef,
    rowData: inventoryData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setSelectedModalData(e.data);
      setItemOpenModal(true);
    },
  };

  const locationGridOptions: GridOptions = {
    columnDefs: inventoryLocationsColDef,
    rowData: inventoryLocations,
    rowSelection: "multiple",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setSelectedLocations([]);
      const isCheckboxColumn =
        e.column.getColId() === "device" ||
        e.column.getColId() === "device_1" ||
        e.column.getColId() === "device_2";
      setSelectedModalData(e.data);
      if (isCheckboxColumn) {
        setLocationOrder(true);
        setLocationOrderModal(true);
        setLocationDevice(
          e.column.getColId() === "device"
            ? "kairo"
            : e.column.getColId() === "device_1"
            ? "nero_single"
            : ("nero_double" as any)
        );
        return;
      } else {
        setLocationOpenModal(true);
        return;
      }
    },
    onSelectionChanged: (e) => {
      let selectedNodes: any = gridRef.current?.api.getSelectedNodes();
      const displayedNodes = e.api?.getDisplayedRowCount();
      if (selectedNodes && displayedNodes < selectedNodes?.length) {
        selectedNodes = [];
        e.api?.forEachNodeAfterFilterAndSort((node: any) =>
          selectedNodes?.push(node)
        );
      }
      if (selectedNodes) {
        const idArray = selectedNodes.map((data: any) => data?.data?.id);
        setSelectedLocations(idArray);
      }
    },
  };

  useEffect(() => {
    gridRef?.current?.api?.setPinnedBottomRowData([
      {
        device: [
          {
            deviceName: "kairo",
            stockQuantity: totalStocks?.kairo || 0,
          },
          {
            deviceName: "nero_single",
            stockQuantity: totalStocks?.nero_single || 0,
          },
          {
            deviceName: "nero_double",
            stockQuantity: totalStocks?.nero_double || 0,
          },
        ],
        openOrders: {
          kairo: totalOrders?.kairo || 0,
          nero_single: totalOrders?.nero_single || 0,
          nero_double: totalOrders?.nero_double || 0,
        },
      },
    ]);
  }, [totalOrders, totalStocks]);

  const orderGridOptions: GridOptions = {
    columnDefs: inventoryOrdersColDef,
    rowData: inventoryOrders,
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setSelectedModalData(e.data);
      setLocationOrderModal(true);
      setLocationOrder(false);
    },
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
    console.log("🚀 ~ handleColumnSort ~ sortedCol:", sortedCol);
  };

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const downloadLocationData = async () => {
    try {
      setIsDownloading(true);
      const filteredLocations = inventoryLocations.filter((location: any) =>
        selectedLocations.includes(location.id)
      );
      const columnsToRender = [
        "name",
        "address",
        "isChannelPartner",
        "isProductionPartner",
        "device",
        "openOrders",
      ];
      getExcelData(filteredLocations, columnsToRender ?? [], false, true);
    } catch (err: any) {
    } finally {
      setIsDownloading(false);
      setTimeout(() => {}, 500);
    }
  };

  const uncheckAllRows = () => {
    const api = gridRef.current?.api;
    if (api) {
      api.forEachNode((node) => {
        const data = node.data;
        data.checked = false;
        api.applyTransaction({ update: [data] });
      });
    }
  };

  const loadData = async () => {
    switch (value) {
      case 0:
        const res = await getInventoryItems();
        setinventoryData(res);
        const itemOptions = generateOptions(res, "id", "name");
        setdeviceOptions(itemOptions);
        break;

      case 1:
        const result = await getInventoryLocations();
        if (result.length > 0) {
          setinventoryLocations(
            result.map((item: any) => ({
              ...item,
              addressConcatenated: `${item.village}, ${item.subDistrict}, ${item.district}, ${item.state}, ${item.country}`,
              openOrders: inventoryData.reduce((acc: any, res: any) => {
                acc[res?.name] = item.order?.[res?.id] ?? 0;
                return acc;
              }, {}),
              device: Object.keys(item?.stock)?.map((key) => {
                const stockItem: any = inventoryData?.find(
                  (val: any) => val.id === key
                );
                return {
                  deviceName: stockItem?.name || "",
                  stockQuantity: item?.stock[key],
                  stockId: stockItem?.id,
                };
              }),
            }))
          );
        }
        break;

      case 2:
        break;

      case 3:
        const orders = await getInventoryOrder();
        if (orders.length > 0) {
          setinventoryOrders(
            orders.map((order: any) => ({
              ...order,
              deviceName: order.inventoryItem?.name,
              location: order?.inventoryLocation?.name,
            }))
          );
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSelectedLocations([]);
    uncheckAllRows();
    loadData();
  }, [
    ItemOpenModal,
    LocationOpenModal,
    MovementOpenModal,
    locationOrderModal,
    value,
  ]);

  const sumOpenOrdersInRange = (array: any) => {
    const devices: string[] = ["kairo", "nero_single", "nero_double"];
    let totalOrders: { [key: string]: number } = {};
    let totalStocks: { [key: string]: number } = {};

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const openOrders = item?.openOrders;
      const stock: [] = item?.device;

      for (const key of devices) {
        if (openOrders.hasOwnProperty(key)) {
          if (!totalOrders.hasOwnProperty(key)) {
            totalOrders[key] = 0;
          }
          totalOrders[key] += openOrders[key];
        }
        const deviceStock: any = stock?.filter(
          (s: any) => s.deviceName === key
        )[0];
        if (deviceStock) {
          if (!totalStocks.hasOwnProperty(key)) {
            totalStocks[key] = 0;
          }
          totalStocks[key] += deviceStock?.stockQuantity;
        }
      }
    }
    setTotalOrders(totalOrders);
    setTotalStocks(totalStocks);
  };

  const addButtonText =
    value === 0
      ? "Item"
      : value === 1
      ? "Location"
      : value === 2
      ? "Movement"
      : value === 3
      ? "Order"
      : null;
  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={3}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Inventory
          </Typography>
        </Grid>
        <Grid display={"flex"} flexDirection={"row"}>
          {value === 1 && (
            <Grid display={"flex"} alignItems={"center"}>
              <label style={{ fontSize: 18, marginRight: 8 }}>
                Channel Partner
              </label>
              <input
                type="checkbox"
                onChange={(e) => setChannelPartner(e.target.checked)}
                checked={channelPartner}
                style={{ width: "20px", height: "20px", marginRight: 30 }}
              ></input>
              <Button
                disabled={isDownloading || selectedLocations.length === 0}
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  letterSpacing: 0.8,
                  display: "block",
                  marginRight: 4,
                  height: 50,
                }}
                variant="outlined"
                onClick={downloadLocationData}
              >
                Download
              </Button>
            </Grid>
          )}
          <Button
            sx={{
              fontSize: 12,
              fontWeight: 600,
              letterSpacing: 0.8,
              display: !!addButtonText ? "block" : "none",
            }}
            variant="outlined"
            onClick={() => {
              setSelectedModalData(null);
              value === 0
                ? setItemOpenModal(true)
                : value === 1
                ? setLocationOpenModal(true)
                : value === 2
                ? setMovementOpenModal(true)
                : setLocationOrderModal(true);
            }}
          >
            {"Add " + addButtonText}
          </Button>
        </Grid>
      </Grid>

      <Tabs value={value} variant="fullWidth">
        <Tab label="Item" onClick={() => handleChange(0)} />
        <Tab label="Location" onClick={() => handleChange(1)} />
        <Tab label="Movement" onClick={() => handleChange(2)} />
        <Tab label="Order" onClick={() => handleChange(3)} />
        <Tab label="Report" onClick={() => handleChange(4)} />
      </Tabs>
      <div style={gridStyle} className="ag-theme-alpine">
        {isArrayWithLength(inventoryData) && value === 0 && (
          <Grid p={3}>
            <AgGridReact
              ref={gridRef}
              rowData={inventoryData}
              columnDefs={inventoryItemColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={15}
              gridOptions={itemGridOptions}
              domLayout="autoHeight"
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
          </Grid>
        )}
      </div>
      <div style={gridStyle} className="ag-theme-alpine">
        {isArrayWithLength(inventoryLocations) && value === 1 && (
          <Grid p={3}>
            <AgGridReact
              ref={gridRef}
              rowData={
                channelPartner
                  ? inventoryLocations?.filter(
                      (location: any) => location?.isChannelPartner === true
                    )
                  : inventoryLocations
              }
              columnDefs={inventoryLocationsColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={15}
              gridOptions={locationGridOptions}
              domLayout="autoHeight"
              onPaginationChanged={(e: any) => {
                const rowsToDisplay = e?.api?.rowModel?.rowsToDisplay || [];
                sumOpenOrdersInRange(
                  rowsToDisplay.map((item: any) => item.data)
                );
              }}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
          </Grid>
        )}
      </div>
      {value === 2 ? (
        <AgGridComponent
          columnDef={inventoryMovementsColDef}
          rowData={inventoryMovements!}
          setSelectedModalData={setSelectedModalData}
          setMovementOpenModal={setMovementOpenModal}
          setinventoryMovements={setinventoryMovements}
          deviceOptions={deviceOptions}
        />
      ) : null}

      <div style={gridStyle} className="ag-theme-alpine">
        {value === 3 ? (
          isArrayWithLength(inventoryOrders) ? (
            <Grid p={3}>
              <AgGridReact
                ref={gridRef}
                rowData={inventoryOrders}
                columnDefs={inventoryOrdersColDef}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                animateRows={true}
                pagination={true}
                paginationPageSize={15}
                gridOptions={orderGridOptions}
                domLayout="autoHeight"
                onPaginationChanged={onPaginationChanged}
                onSortChanged={handleColumnSort}
                onFilterChanged={handleColumnFilter}
              ></AgGridReact>
            </Grid>
          ) : (
            <Typography fontSize={17} color="red" margin={3}>
              There seems to be an error. Please try again!
            </Typography>
          )
        ) : null}
      </div>

      <div style={gridStyle} className="ag-theme-alpine">
        {value === 4 ? <InventoryReport /> : null}
      </div>

      <Modal
        open={locationOrderModal}
        style={{ overflow: "scroll" }}
        onClose={() => setLocationOrderModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <InventoryOrder
            selectedModalData={selectedModalData}
            setOpenModal={setLocationOrderModal}
            locationOrder={locationOrder}
            locationDevice={locationDevice}
            deviceOptions={deviceOptions}
          />
        </Grid>
      </Modal>

      <Modal
        open={ItemOpenModal}
        style={{ overflow: "scroll" }}
        onClose={() => setItemOpenModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <InventoryItem
            selectedModalData={selectedModalData}
            setOpenModal={setItemOpenModal}
            deviceOptions={deviceOptions}
          />
        </Grid>
      </Modal>

      <Modal
        open={LocationOpenModal}
        style={{ overflow: "scroll" }}
        onClose={() => setLocationOpenModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <InventoryLocation
            selectedModalData={selectedModalData}
            setOpenModal={setLocationOpenModal}
            setSelectedModalData={setSelectedModalData}
            setMovementModal={setMovementOpenModal}
          />
        </Grid>
      </Modal>

      <Modal
        open={MovementOpenModal}
        style={{ overflow: "scroll" }}
        onClose={() => setMovementOpenModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <InventoryMovement
            selectedModalData={selectedModalData}
            setOpenModal={setMovementOpenModal}
            deviceOptions={deviceOptions}
          />
        </Grid>
      </Modal>
    </Grid>
  );
};

export default Inventory;
