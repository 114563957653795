import { getHeaders, historicalFixedHeaders } from "./helper";
import moment from "moment";
import ExcelJS from 'exceljs';

import { saveAs } from 'file-saver';

export const saveToXlsxHistorical = async (dataObj, fileName = '', isDaily = false) => {
  const { data } = dataObj;
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Data');
  const headers = historicalFixedHeaders;  
  
  worksheet.columns = headers.map(header => ({
    header: header.label,   
    key: header.value,      
    width: 20,             
  }));

  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

  // Add rows (content)
  data.forEach(d => {
    const rowData = {};
    headers.forEach(header => {
      const fieldKey = header.value; 
      rowData[fieldKey] = 
        fieldKey === "created_date" && d[fieldKey]  
          ? moment(d[fieldKey]).format(isDaily ? "MMMM Do YYYY" : "MMMM Do YYYY, h:mm A")
          : d[fieldKey] || '';  
    });
    worksheet.addRow(rowData);
  });

  // Save to file
  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
  } catch (err) {
    console.error("Error generating file:", err);
  }
};




// Function to save data to Excel
export const saveToXlsxCropData = (dataObj) => {
  const { district_Array, cropDeviceDetails, cropDeviceCount, coromandelData, fileName = 'District_Data' } = dataObj;

  const workbook = new ExcelJS.Workbook();

  // Create first worksheet for Summary data
  const summaryWorksheet = workbook.addWorksheet('Summary');


  summaryWorksheet.columns = [
    { header: 'Coromandel Data', key: 'dataType', width: 30 },
    { header: 'Total', key: 'total', width: 20 }
  ];


  summaryWorksheet.getRow(1).font = { bold: true };


  const coromandelDataRows = [
    { dataType: 'Total Plots', total: coromandelData.totalPlots || 0 },
    { dataType: 'Total Weather unit', total: coromandelData.WEATHER_UNIT || 0 },
    { dataType: 'Total NERO UNIT', total: coromandelData.NERO_UNIT || 0 },
    { dataType: 'Total KAIRO UNIT', total: coromandelData.KAIRO_UNIT || 0 },
    { dataType: 'Total IRRIGATION UNIT', total: coromandelData.IRRIGATION_UNIT || 0 },
    { dataType: 'Total MASTER UNIT', total: coromandelData.MASTER_UNIT || 0 },
    { dataType: 'Inactive plots', total: coromandelData.INACTIVE_plots || 0 },
    { dataType: 'Anomalous plots', total: coromandelData.Anomalous_plots || 0 },
    { dataType: 'Expired Plots', total: coromandelData.expired_plots || 0 }
  ];

  // Add rows to the worksheet
  coromandelDataRows.forEach(row => summaryWorksheet.addRow(row));


// Create second worksheet for crop device details
  const districtWorksheet = workbook.addWorksheet('District Data');
  districtWorksheet.columns = [
    { header: 'District', key: 'city', width: 20 },
    { header: 'State', key: 'state', width: 20 },
    { header: 'Units', key: 'units', width: 10 }
  ];


  districtWorksheet.getRow(1).font = { bold: true };


  district_Array
  .sort((a, b) => {
    if (!a.city) return 1;   
    if (!b.city) return -1;  
    return a.city.localeCompare(b.city);  
  })
  .forEach((d) => {
    districtWorksheet.addRow({
      city: d.city || '',
      state: d.state || '',
      units: d.units || ''
    });
  });

  // Create the third worksheet for crop-wise device counts
  const cropWorksheet = workbook.addWorksheet('Crop Device Details');
  cropWorksheet.columns = [
    { header: 'Crop', key: 'crop', width: 20 },
    { header: 'KAIRO_UNIT', key: 'KAIRO_UNIT', width: 15 },
    { header: 'WEATHER_UNIT', key: 'WEATHER_UNIT', width: 15 },
    { header: 'NERO_UNIT', key: 'NERO_UNIT', width: 15 },
    { header: 'IRRIGATION_UNIT', key: 'IRRIGATION_UNIT', width: 15 },
    { header: 'MASTER_UNIT', key: 'MASTER_UNIT', width: 15 },
    { header: 'Total Devices', key: 'totalDevices', width: 15 }
  ];


  cropWorksheet.getRow(1).font = { bold: true };


  Object.keys(cropDeviceDetails)
    .sort((a, b) => a.localeCompare(b))  
    .forEach((crop) => {
      const cropDetail = cropDeviceDetails[crop];
      cropWorksheet.addRow({
        crop: crop,
        KAIRO_UNIT: cropDetail.KAIRO_UNIT || 0,
        WEATHER_UNIT: cropDetail.WEATHER_UNIT || 0,
        NERO_UNIT: cropDetail.NERO_UNIT || 0,
        IRRIGATION_UNIT: cropDetail.IRRIGATION_UNIT || 0,
        MASTER_UNIT: cropDetail.MASTER_UNIT || 0,
        totalDevices: cropDetail.totalDevices || 0
      });
    });



   // Create the fourth worksheet for Cropwise Data
  const cropWiseWorksheet = workbook.addWorksheet('Crop Wise Devices');

  cropWiseWorksheet.columns = [
    { header: 'Crop', key: 'crop', width: 20 },
    { header: 'Total Devices', key: 'totalDevices', width: 15 },
  ];


  cropWiseWorksheet.getRow(1).font = { bold: true };


  Object.entries(cropDeviceCount)
    .sort(([cropA], [cropB]) => cropA.localeCompare(cropB))
    .forEach(([crop, count]) => {
      cropWiseWorksheet.addRow({
        crop: crop,
        totalDevices: count,
      });
    });


 



  // Write to buffer and trigger download
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
  });
};



