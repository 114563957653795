import React, { useState, useRef, useMemo, useCallback } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { ArrowBack, CalendarMonthOutlined, Clear } from "@mui/icons-material";
import { DatePicker, ToastAlert } from "src/components";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { getSettlementsByDate, getPaymentDetailsByIds } from "src/services/payment.service";

const Settlements = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [tableData, setTableData] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };

  const handleApiError = (message: string) => {
    setError(message);
    setIsToastOpen(true);
  };

  const fetchSettlements = async () => {
    setError("");
    setTableData([]);

    const date = dayjs(selectedDate);

    try {
      // Fetch settlements using the reusable service
      const data = await getSettlementsByDate(date.year(), date.month() + 1, date.date());
      const paymentIds = data.items
        .filter((item: any) => item.type === "payment")
        .map((item: any) => item.entity_id);

      if (paymentIds.length === 0) {
        handleApiError("No payments found for the selected date.");
        return;
      }

      // Fetch payment details for the filtered entity IDs
      await fetchPaymentDetails(paymentIds);
    } catch (err: any) {
      console.error(err);
      handleApiError(err.message || "An unexpected error occurred.");
    }
  };

  const fetchPaymentDetails = async (paymentIds: string[]) => {
    try {
      const data = await getPaymentDetailsByIds(paymentIds); // Reusable function
      setTableData(data);
    } catch (err: any) {
      console.error(err);
      handleApiError(err.message || "An unexpected error occurred.");
    }
  };

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Created Date",
        field: "createdAt",
        flex: 1,
        sortable: true,
        filter: false,
      },
      {
        headerName: "User ID",
        field: "userId",
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Amount",
        field: "amount",
        flex: 1,
        sortable: true,
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "Currency",
        field: "currency",
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Plot Names",
        field: "items",
        flex: 2,
        filter: false,
        cellRenderer: (params: any) => {
          return params.value
            ?.map((item: any) => item.plotName || "N/A")
            .join(", ");
        },
      },
    ];
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
    };
  }, []);

  const onGridReady = useCallback(() => {
    gridRef.current?.api.sizeColumnsToFit();
  }, []);

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Grid
        container
        p={3}
        borderRadius={20}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <IconButton onClick={() => navigate("/")}>
              <ArrowBack />
            </IconButton>
            <Typography m={1} variant="h5">
              Settlements
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setSelectedDate}
                value={selectedDate}
                label="Select Date"
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={fetchSettlements}>
                Load Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <Typography color="error" marginTop={2}>
          {error}
        </Typography>
      )}

      <div
        className="ag-theme-alpine"
        style={{
          flex: "1 1 auto",
          width: "100%",
          overflow: "hidden",
          marginTop: "20px",
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={tableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
        />
      </div>

      <ToastAlert
        open={isToastOpen}
        setOpen={setIsToastOpen}
        severity="error"
        message={error}
      />
    </div>
  );
};

export default Settlements;
