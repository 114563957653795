import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  getAllOrdersForExport,
  getOrdersByPagination,
  getOrdersCount,
  updateOrder,
} from "../services/order.service";
import { ToastAlert } from "src/components";
const Orders = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(15);
  const [error, setError] = useState<string>("");
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [editedOrder, setEditedOrder] = useState<any>({});
  const [editableFields, setEditableFields] = useState<Set<string>>(
    new Set()
  );
  const handleApiError = (message: string) => {
    setError(message);
    setIsToastOpen(true);
  };
  const fetchOrders = async (page: number) => {
    try {
      const skip = page * pageSize;
      const data = await getOrdersByPagination(skip, pageSize);
      setTableData(
        data.map((order: any) => ({
          id: order.id,
          user: order.user || "N/A",
          mobile: order.mobile || "N/A",
          detail: order.detail || "N/A",
          stage: order.stage || "N/A",
          assignedTo: order.assignedTo || "N/A",
          isNewLead: order.isNewLead ? "Yes" : "No",
          createdDate: order.created_date
            ? new Date(order.created_date).toLocaleString()
            : "N/A",
        }))
      );
    } catch (err: any) {
      handleApiError(err.message || "Failed to fetch orders.");
    }
  };
  const fetchOrdersCount = async () => {
    try {
      const count = await getOrdersCount();
      setTotalItems(count);
    } catch (err: any) {
      handleApiError(err.message || "Failed to fetch total order count.");
    }
  };
  useEffect(() => {
    fetchOrders(currentPage);
    fetchOrdersCount();
  }, [currentPage]);
  const handleEditOrder = async () => {
    try {
      const { createdDate, ...sanitizedOrder } = editedOrder;
      sanitizedOrder.isNewLead =
        sanitizedOrder.isNewLead === "Yes" || sanitizedOrder.isNewLead === true;
      await updateOrder(sanitizedOrder.id, sanitizedOrder);
      setSelectedOrder(null);
      fetchOrders(currentPage);
    } catch (err: any) {
      handleApiError(err.message || "Failed to update order.");
    }
  };
  const handleInputChange = (field: string, value: string) => {
    setEditedOrder((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleToggleEditField = (field: string) => {
    setEditableFields((prev) => {
      const updatedSet = new Set(prev);
      if (updatedSet.has(field)) {
        updatedSet.delete(field);
      } else {
        updatedSet.add(field);
      }
      return updatedSet;
    });
  };

  // Function to handle exporting all orders
  const handleExport = async () => {
    try {
      const allOrders = await getAllOrdersForExport();
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Orders");

      worksheet.addRow([
        "Created By",
        "Mobile",
        "Details",
        "Stage",
        "Assigned To",
        "New Lead",
        "Created Date",
      ]);

      allOrders.forEach((order) => {
        worksheet.addRow([
          order.user,
          order.mobile,
          order.detail,
          order.stage,
          order.assignedTo,
          order.isNewLead ? "Yes" : "No",
          order.created_date ? new Date(order.created_date).toLocaleString() : "N/A",
        ]);
      });
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), "ExportedOrdersData.xlsx");
    } catch (err: any) {
      handleApiError(err.message || "Failed to export orders.");
    }
  };
  const columnDefs = [
    { headerName: "Created By", field: "user", flex: 1 },
    { headerName: "Mobile", field: "mobile", flex: 1 },
    { headerName: "Details", field: "detail", flex: 2 },
    { headerName: "Stage", field: "stage", flex: 1 },
    { headerName: "Assigned To", field: "assignedTo", flex: 1 },
    { headerName: "New Lead", field: "isNewLead", flex: 1 },
    { headerName: "Created Date", field: "createdDate", flex: 1 },
  ];
  const fieldLabels: Record<string, string> = {
    user: "Created By",
    mobile: "Mobile Number",
    detail: "Order Details",
    stage: "Order Stage",
    assignedTo: "Assigned To",
    isNewLead: "New Lead",
  };
  return (
    <div style={{ height: "90vh", display: "flex", flexDirection: "column" }}>
      <Grid container p={3} justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Orders</Typography>
        <Button variant="outlined" color="primary" onClick={handleExport}>
          Export Orders
        </Button>
      </Grid>
      <div className="ag-theme-alpine" style={{ flex: 1, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          rowData={tableData}
          columnDefs={columnDefs}
          onRowClicked={(event) => {
            setSelectedOrder(event.data);
            setEditedOrder(event.data);
          }}
          defaultColDef={{ resizable: true, sortable: true }}
        />
      </div>
      <Grid container justifyContent="end" alignItems="center" p={2} spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Typography>
            Page {currentPage + 1} of {Math.ceil(totalItems / pageSize)}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(Math.ceil(totalItems / pageSize) - 1, prev + 1)
              )
            }
            disabled={(currentPage + 1) * pageSize >= totalItems}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      {selectedOrder && (
        <Dialog
          open={!!selectedOrder}
          onClose={() => setSelectedOrder(null)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Order Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={1}>
              {Object.keys(selectedOrder)
                .filter((key) => key !== "id" && key !== "createdDate")
                .map((key) => (
                  <Grid item xs={12} key={key}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        label={fieldLabels[key] || key}
                        value={editedOrder[key] || ""}
                        fullWidth
                        disabled={!editableFields.has(key)}
                        onChange={(e) =>
                          handleInputChange(key, e.target.value)
                        }
                      />
                      <IconButton
                        onClick={() => handleToggleEditField(key)}
                        style={{ marginLeft: 8 }}
                      >
                        <Edit />
                      </IconButton>
                    </div>
                  </Grid>
                ))}
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSelectedOrder(null)}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditOrder}
                  disabled={true}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      <ToastAlert
        open={isToastOpen}
        setOpen={setIsToastOpen}
        severity="error"
        message={error}
      />
    </div>
  );
};
export default Orders;