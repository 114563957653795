import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  getInventoryLocations,
  postInventoryMovement,
  completeInventoryMovement,
  cancelInventoryMovement,
} from "../services/inventory.service";
import Select, { SingleValue, MultiValue } from "react-select";
import { generateOptions } from "src/utils/helper";
import { DatePicker } from "src/components";
import moment from "moment";
import _ from "lodash";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  deviceOptions?: any
}
const InventoryMovement: React.FC<Props> = (props: Props): JSX.Element => {
  const res: any = props?.selectedModalData;
  const [quantity, setQuantity] = useState<any>(res?.quantity || "");
  const [comments, setComments] = useState<any>(res?.comments || "");
  const [trackingId, settrackingId] = useState<any>(res?.trackingId || "");
  const [userId, setUserId] = useState<any>(
    res?.userId || localStorage.getItem("userId")
  );
  const [fromLocation, setFromLocation] = useState<any>(
    res?.fromInventoryLocationId || ""
  );
  const [toLocation, setToLocation] = useState<any>(
    res?.toInventoryLocationId || ""
  );
  const [typeOptions, setTypeOptions] = useState<SingleValue<any>>();
  const [locationOptions, setlocationOptions] = useState<SingleValue<any>>();
  const [selectedDeviceOption, setSelectedDeviceOption] = useState<any>({
    label: res?.inventoryItem?.name || null,
    value: res?.inventoryItem?.id || null,
  });
  const [selectedFromOption, setSelectedFromOption] = useState<any>({
    label: res?.fromInventoryLocation?.name || null,
    value: res?.fromInventoryLocation?.id || null,
  });
  const [selectedToOption, setSelectedToOption] = useState<any>({
    label: res?.toInventoryLocation?.name || null,
    value: res?.toInventoryLocation?.id || null,
  });
  const [selectedTypeOption, setSelectedTypeOption] = useState<any>({
    label: res?.type,
    value: res?.type,
  });
  const [selectedStatusOption, setSelectedStatusOption] = useState<any>({
    label: res?.status || "OPEN",
    value: res?.status || "OPEN",
  });
  const [movementDate, setMovementDate] = useState<Date>(
    res?.id ? moment(res?.date).toDate() : moment().toDate()
  );

  useEffect(() => {
    getInventoryLocations().then((result) => {
      if (result.length > 0) {
        setlocationOptions(
          generateOptions(result, "id", "name", false, "isProductionPartner")
        );
      }
    });

    setTypeOptions(
      generateOptions(
        [
          { id: "ORDER_FULFILLMENT", name: "Order fulfillment" },
          { id: "INTERNAL_TRANSFER", name: "Internal transfer" },
          { id: "INSTALLATION", name: "Installation" },
          { id: "PRODUCTION", name: "Production" },
        ],
        "id",
        "name"
      )
    );
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const hasDevice = !!selectedDeviceOption && !!selectedDeviceOption.value;
    const hasMovementType = !!selectedTypeOption && !!selectedTypeOption.value;
    const hasQuantity = _.isNumber(Number(quantity));

    const isSubmitDisabled = !hasDevice || !hasMovementType || !hasQuantity;

    if (isSubmitDisabled) {
      alert("Please fill required fields");
      return;
    }

    if (
      selectedTypeOption.value === "PRODUCTION" &&
      !locationOptions.find((loc: any) => loc.value === toLocation)
        ?.isProductionPartner
    ) {
      alert("Production movement is not supported on this Location");
      return;
    }

    if (
      !res?.id &&
      userId &&
      quantity &&
      selectedDeviceOption?.value?.length > 0
    ) {
      let finalData = {
        date: movementDate,
        quantity: Number(quantity),
        comments: comments,
        userId: userId,
        inventoryItemId: selectedDeviceOption?.value,
        fromInventoryLocationId:
          fromLocation?.length > 0 && fromLocation !== "--select--"
            ? fromLocation
            : "",
        toInventoryLocationId:
          toLocation?.length > 0 && toLocation !== "--select--"
            ? toLocation
            : "",
        type: selectedTypeOption?.value,
        trackingId: trackingId,
      };
      if (window.confirm("Do you want to submit?")) {
        postInventoryMovement(finalData).then((res: any) => {
          if (res.id) {
            alert("Movement added");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Ocuured");
  };

  const onComplete = () => {
    if (res?.id) {
      if (window.confirm("Do you want to submit?")) {
        completeInventoryMovement(res.id).then((res: any) => {
          if (res.status === "COMPLETED") {
            alert("Movement Completed");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Ocuured");
  };

  const onCancel = () => {
    if (res?.id) {
      if (window.confirm("Do you want to submit?")) {
        cancelInventoryMovement(res.id).then((res: any) => {
          if (res.status === "CANCELED") {
            alert("Movement Canceled");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Ocuured");
  };

  const navigate = useNavigate();

  return (
    <Grid
      width="90%"
      my={2}
      mx="auto"
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid p={2} borderRadius={25} style={{ backgroundColor: "#f5f5f5" }}>
        <Grid container alignItems="center">
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/inventory")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: 10 }}>
            Movement
          </Typography>
        </Grid>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-end"} mr={3}>
        <DatePicker
          showCalendarIcon
          label=""
          maxDate={new Date(Infinity)}
          labelStyle={{ fontWeight: "bold" }}
          value={movementDate}
          onChange={(d: any) => !res?.id && setMovementDate(d)}
        />
      </Grid>
      <div style={{ justifyContent: "center", padding: 20, marginTop: -20 }}>
        <form>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            User Id
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <br />
          <input
            name="userId"
            onChange={(e) => !res?.id && setUserId(e.target.value)}
            value={userId}
            type="text"
            style={{
              width: "93%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <h3 style={{ margin: 5 }}>From Location</h3>
          <Select
            options={locationOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedFromOption(selected);
              if (selected?.value && selected?.value?.length > 0) {
                setFromLocation(selected.value);
              } else {
                setFromLocation("");
              }
            }}
            value={selectedFromOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <h3 style={{ margin: 5 }}>To Location</h3>
          <Select
            options={locationOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedToOption(selected);
              if (selected?.value && selected?.value?.length > 0) {
                setToLocation(selected.value);
              } else {
                setToLocation("");
              }
            }}
            value={selectedToOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <div style={{ display: "flex" }}>
            <h3 style={{ margin: 5 }}>Device Name</h3>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>
          <Select
            options={props.deviceOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedDeviceOption(selected);
            }}
            value={selectedDeviceOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <div style={{ display: "flex" }}>
            <h3 style={{ margin: 5 }}>Movement Type</h3>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
          </div>
          <Select
            options={typeOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedTypeOption(selected);
            }}
            value={selectedTypeOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <div style={{ display: "flex" }}>
            <h3 style={{ margin: 5 }}>Quantity</h3>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
          </div>
          <input
            name="quantity"
            onChange={(e) => !res?.id && setQuantity(e.target.value)}
            value={quantity}
            type="number"
            min={0}
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          <h3 style={{ margin: 5, marginTop: 8 }}>Tracking Id</h3>
          <input
            name="trackingId"
            onChange={(e) => !res?.id && settrackingId(e.target.value)}
            value={trackingId}
            type="text"
            style={{
              width: "93%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          <h3 style={{ margin: 5 }}>Comments</h3>
          <input
            name="comments"
            onChange={(e) => !res?.id && setComments(e.target.value)}
            value={comments}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          {!res?.id && selectedStatusOption?.value === "OPEN" && (
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Submit"
                onClick={onSubmit}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            </Grid>
          )}

          {res?.id && selectedStatusOption?.value === "OPEN" && (
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Complete"
                onClick={onComplete}
                disabled={selectedStatusOption?.value === "COMPLETED"}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
              <input
                type="button"
                value="Cancel"
                onClick={onCancel}
                disabled={selectedStatusOption?.value === "CANCELED"}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#f44336",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            </Grid>
          )}
        </form>
      </div>
    </Grid>
  );
};

export default InventoryMovement;
