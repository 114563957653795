import { SingleValue } from 'react-select';
import { Option } from 'src/constants/interfaces';

export type ButtonType = "icon" | "text";

export type FilterProps = {
  onChange?: (updatedValue: Record<string, any>, filterId?: string) => void;
  onSubmit?: (finalValue: Record<string, any>, filterId?: string) => void;
  onReset?: (filterId?: string) => void;
  onDelete?: (filterId?: string) => void;
  actionButtonType?: ButtonType;
  hasSubmitButton?: boolean;
  hasResetButton?: boolean;
  hasDeleteButton?: boolean;
  defaultBackgroundColor?: string;
  submittedBackgroundColor?: string;
  errorBackgroundColor?: string;
  filterId?: string;
  dropdowns?: AvailableDropdowns[];
  hideTitle?: boolean;
  defaultValue?: any;
  label?: string;
  maxDate?: Date | string;
  minDate?: Date | string;
  isOldId? :boolean
}

type AvailableDropdowns = 
  "crop" | "crop-variety" | "crop-stage" | "disease" | "pest" | "disease-pest-merged" | "observation-types" // crop
  | "state" | "district" | "sub-district" | "village" // location
  | "locale" // locale

export type DropdownState = {
  selected?: Option | null;
  options?: Option[];
  isLoading?: boolean;
  error?: any;
}

export type RenderDropdownArgs<T> = {
  title: string,
  dropdownState: DropdownState | undefined,
  dropdownType: T,
  isDisabled: boolean,
  handleDropdownChange: (dropdown: T, selected: SingleValue<Option>) => void;
}

export const DropdownProps: any = {
  isSearchable: true,
  isClearable: true,
  placeholder: '- select -'
};

export const DefaultDropdownState: DropdownState = {
  options: [],
  isLoading: false,
  selected: null,
  error: null
};

export const DropDownContainerProps = {
  width: "200px",
  item: true
}