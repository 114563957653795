import { Location } from "src/components/Filters/location.filter";
import { CORE_API } from "../constants/api";
import { getData, postData, deleteData } from "./common.service";
import dayjs from "dayjs";

export const getInventoryItems = async (filterKey: string = "", filterList: string[] = []) => {
    var filterClause: Record<string, any> = {};
    if (filterKey != "") {
        filterClause = {
            "where": {
                [filterKey]: {
                    "inq": filterList
                }
            }
        };
    }
    const filterQuery = encodeURIComponent(JSON.stringify(filterClause));
    const url = '/inventory-items?filter=' + filterQuery;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const getInventoryLocations = async (location: Location = {} as Location) => {
    const filterQuery = {
        where: {
            ...location
        }
    }

    const url = '/inventory-locations?filter=' + JSON.stringify(filterQuery);

    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const getFilteredInventoryLocations = async (name?: string) => {
    const filterQuery = {
        "where": { "and": [{ "name": { "like": `.*${name}.*`, "options": "i" } }] }, "fields": ["name", "id"], "limit": 1
    }

    const url = '/inventory-locations?filter=' + JSON.stringify(filterQuery);

    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const getInventoryMovements = async () => {
    const url = '/inventory-movements?filter=' + JSON.stringify({ order: ["date DESC"], include: ["inventoryItem", "fromInventoryLocation", "toInventoryLocation"] });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export async function getInventoryMovementsByPagination(
    skip: number,
    filters: any[] = []
) {

    const qs: any = {
        order: ["date DESC"],
        limit: 15,
        skip: skip,
        include: ["inventoryItem", "fromInventoryLocation", "toInventoryLocation"]
    };
    if (filters.length) {
        qs.where = { and: filters }
    }

    return getData('/inventory-movements?filter=' + JSON.stringify(qs), {
        basePath: CORE_API,
        sendTokenInHeader: true
    })
}

export const getInventoryMovementsByLocation = async (id: any) => {
    const url = '/inventory-movements?filter=' + JSON.stringify({
        order: ["date DESC"],
        include: ["inventoryItem", "fromInventoryLocation", "toInventoryLocation"],
        where: {
            or: [
                { fromInventoryLocationId: id },
                { toInventoryLocationId: id }
            ]
        }
    });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}


export const postInventoryItem = async (payload: any) => {
    const url = '/inventory-items';
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export const putInventoryItem = async (payload: any, id: any) => {
    const url = `/inventory-items/${id}`;
    return postData(url, {
        sendTokenInHeader: true,
        usePatchMethod: true,
        basePath: CORE_API,
        body: payload
    })
}

export const deleteInventoryItem = async (id: any) => {
    const url = `/inventory-items/${id}`;
    return deleteData(url, {
        sendTokenInHeader: true,
        useDeleteMethod: true,
        basePath: CORE_API,
    })
}

export const postInventoryLocation = async (payload: any) => {
    const url = '/inventory-locations';
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export const patchInventoryLocation = async (payload: any, id: any) => {
    const url = `/inventory-locations/${id}`;
    return postData(url, {
        sendTokenInHeader: true,
        usePatchMethod: true,
        basePath: CORE_API,
        body: payload
    })
}

export const deleteInventoryLocation = async (id: any) => {
    const url = `/inventory-locations/${id}`;
    return deleteData(url, {
        sendTokenInHeader: true,
        useDeleteMethod: true,
        basePath: CORE_API,
    })
}

export const postInventoryMovement = async (payload: any) => {
    const url = '/inventory-movements';
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export const getInventoryOrder = async () => {
    const url = '/inventory-orders?filter=' + JSON.stringify({ order: ["date DESC"], include: ["inventoryItem", "inventoryLocation"] });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const postInventoryOrder = async (payload: any) => {
    const url = '/inventory-orders';
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export const completeInventoryMovement = async (id: any) => {
    const url = '/inventory-movements/' + id + '/complete';
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const cancelInventoryMovement = async (id: any) => {
    const url = '/inventory-movements/' + id + '/cancel';
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const completeInventoryOrder = async (id: any) => {
    const url = '/inventory-orders/' + id + '/complete';
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const cancelInventoryOrder = async (id: any) => {
    const url = '/inventory-orders/' + id + '/cancel';
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    })
}

export const deleteInventoryOrder = async (id: any) => {
    const url = '/inventory-orders/' + id;
    return deleteData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        useDeleteMethod: true
    })
}

/**
 * Inventory Locations
 */
export const getInventoryReport = async (args: InventoryReportArgs): Promise<InventoryReportResponse> => {
    const { inventoryLocationId, startTime, inventoryItemId } = args;
    var url = `/inventory-locations/get-report`;
    const hasDate = dayjs(startTime).isValid();
    if (hasDate)
        url += `?startTime=${startTime}`;
    if (inventoryItemId) {
        url += hasDate ? '&' : '?'
        url += `inventoryItemId=${inventoryItemId}`;
    }
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: inventoryLocationId
    })
}

type InventoryReportArgs = {
    inventoryLocationId: string[];
    startTime: string;
    inventoryItemId: string;
}

export type InventoryReport = {
    "openOrders": number,
    "completedOrders": number,
    "intransitOrders": number,
    "totalOrders": number,
    "dispatchedOrders": number,
    "inventoryStocks": number
}

export type InventoryReportResponse = {


    [itemId: string]: {
        summary: InventoryReport,
        locations: Record<string, InventoryReport & { name: string }>
    }
}