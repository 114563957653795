import {
  ValueFormatterParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
} from "ag-grid-community";
import moment from "moment";
import { KeyValues } from "./interfaces";

// was throwing an error when importing as { GridCropFilter, GridCustomHeader } from 'src/components even though code is same
import CropFilter from "src/components/ag-grid/CropFilter";
import CustomHeader from "src/components/ag-grid/CustomHeader";
import PhoneRenderer from "src/components/PhoneRenderer";
import Location from "src/components/Location";
import {
  QualityRenderer,
  QuantityRenderer,
  NeroDoubleInventoryRenderer,
  NeroSingleInventoryRenderer,
  KairoInventoryRenderer,
} from "src/utils/GridDataRenderer";
import dayjs from "dayjs";

var dateFormatter: any = (params: { value: any }) => {
  if (!params.value) return params.value;
  return params.value.split("T")[0];
};

const mappingOfsoil = (params: any) => {
  const soilTypeId = params.value;
  const soilMapping: Record<string, string> = {
    1: "Sandy Loam",
    2: "Sandy Loam",
    3: "Clay",
    4: "Fine Sandy Loam",
    5: "Fine Sandy Loam",
    6: "Clay",
  };
  return soilMapping[soilTypeId] || "Unknown Soil Type";
};

const booleanFormatter = (params: { value: any }) => {
  // return (params.value == true) ? "True" : "false";
  if (params.value == true) return "True";
  else if (params.value == false) return "False";
  else return " ";
};

const booleanFormatter2 = (params: { value: any }) => {
  return params.value == false ? "True" : "False";
};
function dateFormatterLead(params: { value: any }) {
  const date = new Date(params.value);
  if (isNaN(date.getTime())) {
    return params.value;
  }
  const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

  return formattedDate;
}

var dateFilterParams = {
  filters: [
    {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterDate: Date, cellValue: string) {
          if (cellValue == null) return -1;
          return getDate(cellValue).getTime() - filterDate.getTime();
        },
      },
    },
    {
      filter: "agSetColumnFilter",
      filterParams: {
        comparator: function (a: string, b: string) {
          return getDate(a).getTime() - getDate(b).getTime();
        },
      },
    },
  ],
};



function parseDate(params: ValueFormatterParams): string {
  if (!params.value) {
    return "N/A";
  }
  return moment(params.value).format("LLL");
}

function getDate(value: string) {
  var dateParts = value.split("/");
  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
}

export const exporterColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Plot Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Farmer Name",
    field: "farmUser.name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Quantity",
    headerComponent: CustomHeader,
    field: "quantity",
    sortable: true,
    filter: "agNumberColumnFilter",
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const quantityDetails = {
        component: QuantityRenderer,
      };
      return quantityDetails;
    },
  },
  {
    headerName: "Quality",
    field: "quality",
    maxWidth: 250,
    sortable: true,
    filter: "agNumberColumnFilter",
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const qualityDetails = {
        component: QualityRenderer,
      };
      return qualityDetails;
    },
  },
];

export const regionCropColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Region",
    field: "regionId",
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop",
    field: "cropId",
    sortable: true,
    filter: "agTextColumnFilter",
  },
]

export const columnDef: (ColDef | ColGroupDef | KeyValues)[] = [
  // default show
  // headerName is for column header
  {
    headerName: "Plot Id",
    field: "plotId",
    cellRenderer: PhoneRenderer,
    filter: "agTextColumnFilter",
    // pinned: "left",
    // minWidth:290,
    maxWidth: 130,
  },
  {
    headerName: "Plot Name",
    field: "name",
    filter: "agTextColumnFilter",
    minWidth: 130,
    resizable: true 
  },
  {
    headerName: "Farmer Id",
    field: "farmUserId",
    maxWidth: 150,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Farm Id",
    field: "farmId",
    maxWidth: 150,
    filter: "agTextColumnFilter",
    hide:true
  },
  
  {
    headerName: "Farmer Name",
    field: "farmUser.name",
    filter: "agTextColumnFilter",
    maxWidth: 200,
  },
  {
    headerName: "Farmer Mobile",
    field: "farmUser.mobile",
    maxWidth: 150,
    filter: "agTextColumnFilter",
  },
 
  
  {
    headerName: "Farm Name",
    field: "farm.name",
    maxWidth: 150,
    filter: "agTextColumnFilter",

  },
  {
    headerName: "Location",
    // maxWidth: 130,
    cellRenderer: Location,
    field: `stateAndDistrict`,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Soil Type",
    field: "soilTypeId",
    filter: 'agTextColumnFilter',
    valueFormatter:mappingOfsoil,
    maxWidth: 130,
  },
  // {
  //   headerName: 'State',
  //   // maxWidth: 130,
  //   field: 'stateAndDistrict',
  //   filter: 'agTextColumnFilter',
  // },
  {
    headerName: "Crop",
    maxWidth: 130,
    field: "cropSown.name",
    filter: CropFilter,
    floatingFilter: true,
    filterParams: {
      title: "Select Crops",
    },
  },
  

  // hidden default
  {
    headerName: "Area",
    maxWidth: 150,
    field: "area",
    // filter: 'agNumberColumnFilter',
    hide: true,
  },

  {
    headerName: "Geo Location",
    // maxWidth: 130,
    field: `location`,
    valueFormatter: (params: any) => `Latitude: ${params?.data?.location?.lat}, Longitude: ${params?.data?.location?.lng}`,
    hide: true
  },


  // default hidden
  {
    headerName: "Place",
    field: "place",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Taluka",
    field: "taluka",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Plot Type",
    field: "plotType",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Dipper Discharge",
    field: "dipperDischarge",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Per Plant Dipper",
    field: "perPlantDipper",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Plant Distance",
    field: "plantDistance",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Row Distance",
    field: "Row Distance",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  
  {
    headerName: "Plantation Year",
    field: "plantationYear",
    // filter: 'agTextColumnFilter',
    valueFormatter: parseDate,
    hide: true,
  },

  // farm
  {
    headerName: "Farm Id",
    field: "farm.farmId",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Farm Area",
    field: "farm.farmArea",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Altitude",
    field: "farm.altitude",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Agronomist",
    field: "farm.agronomistId",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Farm Manager",
    field: "farm.farmManagerId",
    // filter: 'agTextColumnFilter',
    hide: true,
  },

  // cropSown
  {
    headerName: "Crop Active Stage",
    field: "cropSown.activeStage",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Crop Variety",
    field: "cropSown.varietyId",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Crop Ended",
    field: "cropSown.isCropEnded",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Sowing Date",
    field: "cropSown.sowingDate",
    filter: 'agTextColumnFilter',
    hide: false,
    valueFormatter: dateFormatter,
    maxWidth: 130,
  },

  // device
  {
    headerName: "Device Type",
    field: "device.deviceType",
    maxWidth: 130,
    //sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: "Created Date",
    field: "created_date",
    sortable: true,
    filter: "agTextColumnFilter",
    hide: false,
    valueFormatter: dateFormatter,
    maxWidth: 130,
  },
  {
    headerName: "Device IMEI",
    field: "device.imei",
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Sensors",
    field: "device.sensors",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Soil Moisture 1 Depth",
    field: "device.soilMoisture1Depth",
    filter: 'agTextColumnFilter',
    hide: false,
   
  },
  {
    headerName: "Soil Moisture 2 Depth",
    field: "device.soilMoisture2Depth",
    filter: 'agTextColumnFilter',
    // hide: true,
  },
  {
    headerName: "Data Interval",
    field: "device.dataInterval",
    // sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },

  // farm user
  {
    headerName: "Farmer Id",
    field: "farmUser.farmUserId",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Farmer Mail",
    field: "farmUser.email",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Farmer Language",
    field: "farmUser.lang",
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Do not contact",
    field: "farmUser.doNotContact",
    //filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Last Conversation",
    field: "farmUser.lastInteractionDate",
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: dateFormatter,
    maxWidth: 150,
    hide:true
    // pinned: 'right',
  },
];

export const MaintenanceCols: (ColDef | ColGroupDef | KeyValues)[] = [
  // default show
  {
    headerName: "Created At",
    field: "created_date",
    filter: "agTextColumnFilter",
    valueFormatter: dateFormatter,
    pinned: "left",
    minWidth: "130",
    maxWidth: "150",
  },
  {
    headerName: "Deadline",
    field: "date",
    filter: "agTextColumnFilter",
    valueFormatter: dateFormatter,
    pinned: "left",
    minWidth: "130",
    maxWidth: "150",
  },
  {
    headerName: "Details",
    field: "details",
    filter: "agTextColumnFilter",
    pinned: "right",
    minWidth: "300",
  },
  {
    headerName: "Plot Id",
    field: "plotId",
    filter: "agTextColumnFilter",
    maxWidth: "220",
  },
  {
    headerName: "Farm Id",
    field: "farmId",
    filter: "agTextColumnFilter",
    maxWidth: "220",
  },
  {
    headerName: "Farm User Id",
    field: "farmUserId",
    filter: "agTextColumnFilter",
    maxWidth: "220",
  },
  {
    headerName: "Serviced By",
    field: "servicedBy",
    filter: "agTextColumnFilter",
    maxWidth: "220",
  },
];

export const FollowUpCols: (ColDef | ColGroupDef | KeyValues)[] = [
  // default show
  {
    headerName: "Follow Up",
    field: "followupDate",
    // sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: dateFormatter,
    pinned: "right",
    minWidth: "130",
    maxWidth: "150",
  },
  {
    headerName: "Farm User ID",
    field: "farmUserId",
    filter: "agTextColumnFilter",
    // sortable: true,
    maxWidth: "200",
  },
  // {
  //   headerName: 'Plot Id',
  //   field: 'plotId',
  //   filter: 'agTextColumnFilter',
  //   //sortable: true,
  // },
  // {
  //   headerName: 'Farm Id',
  //   field: 'farmId',
  //   filter: 'agTextColumnFilter',
  //   //sortable: true,
  // },
  {
    headerName: "Last Interaction",
    field: "lastInteractionDate",
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: dateFormatter,
    maxWidth: 150,
    pinned: "right",
  },
  {
    headerName: "CRM User",
    field: "supportUserId",
    filter: "agTextColumnFilter",
    maxWidth: "150",
    // sortable: true,
  },
  {
    headerName: "Fyllo",
    field: "conversations.0.fyllo",
    filter: "agTextColumnFilter",
    //sortable: true,
  },
  {
    headerName: "Farmer",
    field: "conversations.0.farmer",
    filter: "agTextColumnFilter",
    //sortable: true,
  },
  {
    headerName: "Area",
    maxWidth: 150,
    field: "area",
    hide: true,
    //sortable: true,
  },

  // default hidden
  {
    headerName: "Place",
    field: "place",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Plot Type",
    field: "plotType",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Soil Type",
    field: "soilTypeId",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Plantation Year",
    field: "plantationYear",
    valueFormatter: parseDate,
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },

  // farm
  {
    headerName: "Farm Id",
    field: "farm.farmId",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Farm Area",
    field: "farm.farmArea",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Altitude",
    field: "farm.altitude",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: "Crop Active Stage",
    field: "cropSown.activeStage",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: false,
  },
  {
    headerName: "Crop Variety",
    field: "cropSown.varietyId",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: false,
  },
  {
    headerName: "Crop Sowing Date",
    field: "cropSown.sowingDate",
    //sortable: true,
    // filter: 'agTextColumnFilter',
    hide: false,
  },
];

export const farmActivitiesColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Suggested Date",
    field: "suggestedDate",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: parseDate,
    sort: "desc",
  },
  {
    headerName: "Done Date",
    field: "doneDate",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: parseDate,
    sort: "desc",
  },
  {
    headerName: "Activity",
    field: "farmActivityTypeId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Plot Id",
    field: "plotId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Plot Name",
    field: "plot.name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Notes",
    field: "notes",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Place",
    field: "plot.place",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 200,
  },
];

export const fertilizersColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Id",
    field: "id",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Alias",
    field: "alias",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Unit",
    field: "unit",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Soil Application",
    field: "usedInSoilApplication",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Fertigation",
    field: "usedInFertigation",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Foliar Application",
    field: "usedInFoliation",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Group",
    field: "group",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const ConsultantsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Mobile No.",
    maxWidth: 150,
    field: "mobile",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Region Id",
    field: "regionId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop Id",
    field: "cropId",
    maxWidth: 150,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Created Date",
    field: "created_date",
    valueFormatter: dateFormatter,
    maxWidth: 150,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const VideoGuidesColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Video Title",
    field: "title",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "keywords",
    field: "keywords",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Created At",
    field: "created_date",
    maxWidth: 150,
    valueFormatter: dateFormatter,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Language",
    field: "lang",
    maxWidth: 150,
    cellClassRules: {
      // apply green to 2008
      "rag-green-outer": (params) => params.value === "en",
      // apply amber 2004
      "rag-amber-outer": (params) => params.value === "ma",
      // apply red to 2000
      "rag-red-outer": (params) => params.value === "hi",
      // apply red to 2000
      "rag-blue-outer": (params) => params.value === "ka",
    },
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Category",
    field: "category",
    maxWidth: 150,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Priority",
    field: "priority",
    autoHeight: true,
    maxWidth: 150,
    sortable: true,
    cellStyle: (params) => {
      if (params.value === 1) {
        //mark police cells as red
        return { color: "red" };
      } else if (params.value == 2) {
        return { color: "blue" };
      } else {
        return { color: "green" };
      }
      return null;
    },
    filter: "agTextColumnFilter",
  },
];

export const NotiColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Date",
    field: "date",
    maxWidth: 150,
    valueFormatter: dateFormatter,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Valid Till",
    field: "validTill",
    maxWidth: 150,
    valueFormatter: dateFormatter,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Farm User ID",
    field: "farmUserId",
    autoHeight: true,
    sortable: true,
    maxWidth: 200,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Important",
    field: "isImportant",
    maxWidth: 150,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: "red" };
      } else {
        return { color: "green" };
      }
      return null;
    },
  },
  {
    headerName: "notifTypeId",
    field: "notifTypeId",
    maxWidth: 150,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Seen",
    field: "seen",
    maxWidth: 100,
    autoHeight: true,
    sortable: true,
    valueFormatter: booleanFormatter,
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: "red" };
      } else {
        return { color: "green" };
      }
      return null;
    },
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Show Popup",
    field: "showPopup",
    maxWidth: 150,
    autoHeight: true,
    sortable: true,
    valueFormatter: booleanFormatter,
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: "red" };
      } else {
        return { color: "green" };
      }
      return null;
    },
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Text",
    field: "text",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const nutrientsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Type",
    field: "petioleAnalysisStageId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop Variety",
    field: "cropVarietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Root Stock",
    field: "rootStockId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const appNotifsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Date",
    field: "date",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: parseDate,
    sort: "desc",
    width: 230
  },
  {
    headerName: "Message",
    field: "text",
    autoHeight: true,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    width: 900
  },
];

export const pesticidesColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Variety",
    field: "cropVarietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Base Stage",
    field: "config.baseStage",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Purpose",
    field: "config.purpose",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Note",
    field: "config.note",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Days After BaseStage",
    field: "config.daysAfterBaseStage",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const ruleColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Type",
    field: "type",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Type Id",
    field: "typeId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Days",
    field: "days",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Minimum Days",
    field: "minDays",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Severity",
    field: "severity",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const inventoryItemColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Description",
    field: "description",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Grouped Item",
    field: "isGroupedItem",
    maxWidth: 300,
    autoHeight: true,
    sortable: true,
    valueFormatter: (params) => {
      if (params.data.isGroupedItem) {
        return 'True';
      } else {
        return 'False';
      }
    },
    cellStyle: (params) => {
      if (params.data.isGroupedItem === true) {
        return { color: "green" };
      }
    },
    filter: "agTextColumnFilter",
  },
];

export const LeadColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Created Date",
    field: "created_date",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Name",
    field: "contactName",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Contact",
    field: "contactPhoneNumber",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Location",
    field: "location",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Serviced By",
    field: "servicedBy",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Follow Up Date",
    field: "followUpDate",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Status",
    field: "status",
    autoHeight: true,
    filter: "agTextColumnFilter"
  },
  {
    headerName: "Created By",
    field: "created_by",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Priority",
    field: "priority",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Source",
    field: "source",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Comment",
    field: "comment",
    autoHeight: true,
    filter: "agTextColumnFilter",
  }
]

export const inventoryLocationsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellStyle: function (params) {
      if (params?.data?.isChannelPartner) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: "Contact",
    field: "contact_no",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Address",
    field: `addressConcatenated`,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Stock",
    autoHeight: true,
    children: [
      {
        headerName: "Kairo",
        field: "device",
        maxWidth: 100,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          const qualityDetails = {
            component: KairoInventoryRenderer,
          };
          return qualityDetails;
        },
      },
      {
        headerName: "Nero Single",
        field: "device",
        maxWidth: 120,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          const qualityDetails = {
            component: NeroSingleInventoryRenderer,
          };
          return qualityDetails;
        },
      },
      {
        headerName: "Nero Double",
        field: "device",
        maxWidth: 120,
        cellRendererSelector: (params: ICellRendererParams<any>) => {
          const qualityDetails = {
            component: NeroDoubleInventoryRenderer,
          };
          return qualityDetails;
        },
      },
    ]
  },
  {
    headerName: "Order",
    autoHeight: true,
    children: [
      {
        headerName: "Kairo",
        field: "openOrders.kairo",
        maxWidth: 100,
      },
      {
        headerName: "Nero Single",
        field: "openOrders.nero_single",
        maxWidth: 120,
      },
      {
        headerName: "Nero Double",
        field: "openOrders.nero_double",
        maxWidth: 120,
      },
    ]
  },

];

export const inventoryMovementsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Date",
    field: "date",
    autoHeight: true,
    sortable: true,
    valueFormatter: parseDate,
    filter: "agDateColumnFilter",
    pinned: "left",
    minWidth: 200,
  },
  {
    headerName: "User",
    field: "userId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "Plot Id",
    field: "plotId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "Farm User",
    field: `farmUserId`,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "Type",
    field: "type",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "Quantity",
    field: "quantity",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 150,
  },
  {
    headerName: "Inventory Item",
    field: `deviceName`,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "From Location",
    field: "fromLocation",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "From Inv Closing Stock",
    field: "fromInvLocClosingStock",
    autoHeight: true,
    sortable: true,
    filter: "agNumberColumnFilter",
    minWidth: 150,
  },
  {
    headerName: "To Location",
    field: "toLocation",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "To Inv Closing Stock",
    field: "toInvLocClosingStock",
    filter: "agNumberColumnFilter",
    autoHeight: true,
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: "Status",
    field: `status`,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
  }
];

export const inventoryOrdersColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Date",
    field: "date",
    autoHeight: true,
    sortable: true,
    valueFormatter: parseDate,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Location",
    field: "location",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Item",
    field: `deviceName`,
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Quantity",
    field: "quantity",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Comments",
    field: "comments",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  }
];

export const UserRegionCropColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Region",
    field: "regionId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Crop",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const localeColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Id",
    field: "id",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "English",
    field: "en",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Hindi",
    field: "hi",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Marathi",
    field: "ma",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Gujarati",
    field: "gu",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Kannada",
    field: "ka",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Telugu",
    field: "te",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Tamil",
    field: "ta",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const laiAdvisoryColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Crop Variety",
    field: "cropVarietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Stage",
    field: "stageId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Week",
    field: "week",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Min Lai",
    field: "minLai",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Max Lai",
    field: "maxLai",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Min Optimal Lai",
    field: "minOptimalLai",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Max Optimal Lai",
    field: "maxOptimalLai",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const idpmConfigDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Disease/Pest",
    field: "diseasePestId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Items",
    field: "items",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  }
];

export const paymentDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Created Date",
    field: "createdAt",
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: (params) => dayjs(params.value).format('LLL')
  },
  {
    headerName: "User Id",
    field: "userId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Plot Id",
    field: "items",
    filter: "agTextColumnFilter",
    autoHeight: true,
    sortable: true,
    valueGetter: params => params.data.items.map((item: any) => `${item.plotId}`).join(', '),
    minWidth: 550
  },
  {
    headerName: "Created/Modified By",
    valueGetter: (params: any) => (params.data?.modifiedBy || params.data?.createdBy),
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Amount",
    field: "amount",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Status",
    field: "status",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    cellRenderer: 'statusButtonRenderer'
  }
];

export const cropCharacteristicsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Season",
    field: "season",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Crop Variety",
    field: "varietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Total Stages",
    field: "totalStage",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop Stages",
    field: "cropStageIds",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Days in stages",
    field: "numberOfDaysinStages",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const SalesDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Date",
    field: "created_date",
    sortable: true,
    filter: "agTextColumnFilter",
    valueFormatter: (params) => dayjs(params.value).format('MMM YYYY')
  },
  {
    headerName: "State",
    field: "state",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "District",
    field: "district",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const petioleColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    maxWidth: 150,
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Stage Id",
    field: "stageId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  }
];

export const farmUsersColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "User Id",
    field: "farmUserId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Mobile",
    field: "mobile",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Email",
    field: "email",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Regions",
    field: "regionIds",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 400
  },
  {
    headerName: "Receivables",
    field: "receivables",
    autoHeight: true,
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 120
  },
  {
    headerName: "Receivables Date",
    field: "receivablesDate",
    autoHeight: true,
    sortable: true,
    valueFormatter: (params) => {
      if (params.value) {
        return dayjs(params.value).format('DD MMM YYYY');
      }
      return '';
    }
  }
];

export const sprayConfigDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Stage",
    field: "stageId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Disease/Pest",
    field: "diseasePestId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Spray",
    autoHeight: true,
    children: [
      {
        headerName: "Nil",
        field: "NIL",
        maxWidth: 100,
      },
      {
        headerName: "Low",
        field: "LOW",
        maxWidth: 100,
      },
      {
        headerName: "Medium",
        field: "MEDIUM",
        maxWidth: 120,
      },
      {
        headerName: "High",
        field: "HIGH",
        maxWidth: 120,
      },
    ]
  },
];

export const cropVarietyDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Id",
    field: "id",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const cropStageDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop Variety",
    field: "cropVarietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Stage Id",
    field: "stageId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },

  {
    headerName: "Order",
    field: "order",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Duration",
    field: "duration",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

export const cropDiseaseDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop Variety",
    field: "cropVarietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Disease Id",
    field: "diseaseId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },

  {
    headerName: "Applicable Stages",
    field: "applicableStages",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  }
];

export const cropPestDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Crop Id",
    field: "cropId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Crop Variety",
    field: "cropVarietyId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Pest Id",
    field: "pestId",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },

  {
    headerName: "Applicable Stages",
    field: "applicableStages",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  }
];

export const RegionColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Name",
    field: "name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    headerName: "Type",
    field: "type",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "District",
    field: 'district',
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },

  {
    headerName: "State",
    field: 'state',
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter"
  },

  {
    headerName: "Country",
    field: 'country',
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter"
  },
  {
    headerName: "Target Region",
    field: 'targetRegion',
    autoHeight: true,
    sortable: true,
    editable: true,
    filter: "agTextColumnFilter",
    cellEditor: 'agSelectCellEditor',

    cellEditorParams: {
      values: [true, false],
    },
    valueFormatter: params => params.value ? 'True' : 'False',
  }
]
export const RegionReportColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: 'Region',
    pinned: "left",
    minWidth: 300,
    field: "name",
    autoHeight: true,
    sortable: true,
    cellStyle: { fontSize: '20px' }
  },

  {
    headerName: 'Revenue',
    children: [
      {
        headerName: 'Target',
        minWidth: 100,
        field: 'revenue.target',
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { backgroundColor: '#FFEBEE', fontSize: '20px' }
          }
          return { backgroundColor: '#EF9A9A', fontSize: '20px' }
        },
        editable: true,
        valueParser: (params) => parseFloat(params.newValue),
      },
      {
        headerName: 'Actual',
        field: 'revenue.actual',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { backgroundColor: '#FFEBEE', fontSize: '20px' }
          }
          return { backgroundColor: '#EF9A9A', fontSize: '20px' }
        },
        editable: true,
        valueParser: (params) => parseFloat(params.newValue),
      },
    ]
  },

  {
    headerName: 'Collections',
    children: [
      {
        headerName: 'Recievable',
        children: [
          {
            headerName: 'Target',
            field: 'collections.recievable.target',
            minWidth: 100,
            autoHeight: true,
            sortable: true,
            cellStyle: params => {
              if (params.rowIndex % 2 === 0) {
                return { fontSize: '20px', backgroundColor: '#E0F7FA' }
              }
              return { fontSize: '20px', backgroundColor: '#B2EBF2' }
            },
            valueParser: (params) => parseFloat(params.newValue),
            editable: true,
          },
          {
            headerName: 'Actual',
            field: 'collections.recievable.actual',
            minWidth: 100,
            autoHeight: true,
            sortable: true,
            cellStyle: params => {
              if (params.rowIndex % 2 === 0) {
                return { fontSize: '20px', backgroundColor: '#E0F7FA' }
              }
              return { fontSize: '20px', backgroundColor: '#B2EBF2' }
            },
            valueParser: (params) => parseFloat(params.newValue),
            editable: true,
          },

        ]
      },
      {
        headerName: 'Fresh Sales',
        children: [
          {
            headerName: 'Target',
            field: 'collections.freshSales.target',
            minWidth: 100,
            autoHeight: true,
            sortable: true,
            cellStyle: params => {
              if (params.rowIndex % 2 === 0) {
                return { fontSize: '20px', backgroundColor: '#E0F7FA' }
              }
              return { fontSize: '20px', backgroundColor: '#B2EBF2' }
            },
            valueParser: (params) => parseFloat(params.newValue),
            editable: true,
          },
          {
            headerName: 'Actual',
            field: 'collections.freshSales.actual',
            minWidth: 100,
            autoHeight: true,
            sortable: true,
            cellStyle: params => {
              if (params.rowIndex % 2 === 0) {
                return { fontSize: '20px', backgroundColor: '#E0F7FA' }
              }
              return { fontSize: '20px', backgroundColor: '#B2EBF2' }
            },
            valueParser: (params) => parseFloat(params.newValue),
            editable: true,
          },
        ]
      }
    ]
  },

  {
    headerName: 'Installation',
    minWidth: 300,
    children: [
      {
        headerName: 'Target',
        field: 'installation.target',
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
        valueParser: (params) => parseFloat(params.newValue),
        editable: true,
      },
      {
        headerName: 'Actual',
        field: 'installation.actual',
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
        valueParser: (params) => parseFloat(params.newValue),
        editable: true,
      },

    ]
  }

]
export const EstimationRegionReportColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: 'Region',
    pinned: "left",
    minWidth: 300,
    field: 'name',
    autoHeight: true,
    sortable: true,
    cellStyle: params => {
      if (params.rowIndex % 2 === 0) {
        return { background: '#f0f0f0', fontSize: '20px' }; // Light color for even rows
      }
      return { background: '#d3d3d3', fontSize: '20px' }; // Darker color for odd rows
    },
  },
  {
    headerName: 'Revenue',
    children: [
      {
        headerName: 'Start',
        field: 'revenue.estimation.start',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { backgroundColor: '#FFEBEE', fontSize: '20px' }
          }
          return { backgroundColor: '#EF9A9A', fontSize: '20px' }
        },
      },
      {
        headerName: '1st',
        field: 'revenue.estimation.first',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { backgroundColor: '#FFEBEE', fontSize: '20px' }
          }
          return { backgroundColor: '#EF9A9A', fontSize: '20px' }
        },
      },
      {
        headerName: '2nd',
        field: 'revenue.estimation.second',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { backgroundColor: '#FFEBEE', fontSize: '20px' }
          }
          return { backgroundColor: '#EF9A9A', fontSize: '20px' }
        },
      },
      {
        headerName: 'Actual',
        minWidth: 100,
        field: 'revenue.actual',
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { backgroundColor: '#FFEBEE', fontSize: '20px' }
          }
          return { backgroundColor: '#EF9A9A', fontSize: '20px' }
        },
      }
    ]
  },

  {
    headerName: 'Collections',
    minWidth: 300,
    children: [
      {
        headerName: 'Start',
        field: 'collections.estimation.start',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { fontSize: '20px', backgroundColor: '#E0F7FA' }
          }
          return { fontSize: '20px', backgroundColor: '#B2EBF2' }
        },
      },
      {
        headerName: '1st',
        field: 'collections.estimation.first',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { fontSize: '20px', backgroundColor: '#E0F7FA' }
          }
          return { fontSize: '20px', backgroundColor: '#B2EBF2' }
        },
      },
      {
        headerName: '2nd',
        field: 'collections.estimation.second',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { fontSize: '20px', backgroundColor: '#E0F7FA' }
          }
          return { fontSize: '20px', backgroundColor: '#B2EBF2' }
        },
      },
      {
        headerName: 'Actual',
        field: 'collections.estimation.end',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 === 0) {
            return { fontSize: '20px', backgroundColor: '#E0F7FA' }
          }
          return { fontSize: '20px', backgroundColor: '#B2EBF2' }
        },
      }
    ]
  },
  {
    headerName: 'Installation',
    minWidth: 300,
    children: [
      {
        headerName: 'Start',
        field: 'installation.estimation.start',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      },
      {
        headerName: '1st',
        field: 'installation.estimation.first',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      },
      {
        headerName: '2nd',
        field: 'installation.estimation.second',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      },
      {
        headerName: 'Actual',
        field: 'installation.estimation.end',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      }
    ]
  },
  {
    headerName: 'Weekly Installation',
    minWidth: 300,
    children: [
      {
        headerName: '1st week',
        field: 'weeklyInstallation.first',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        valueGetter: (params: any) => (params?.data?.weeklyInstallation?.first || 0),
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      },
      {
        headerName: '2nd week',
        field: 'weeklyInstallation.second',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        valueGetter: (params: any) => (params?.data?.weeklyInstallation?.second || 0),
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      },
      {
        headerName: '3rd week',
        field: 'weeklyInstallation.third',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        valueGetter: (params: any) => (params?.data?.weeklyInstallation?.third || 0),
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      },
      {
        headerName: '4th week',
        field: 'weeklyInstallation.fourth',
        minWidth: 100,
        autoHeight: true,
        sortable: true,
        valueGetter: (params: any) => (params?.data?.weeklyInstallation?.fourth || 0),
        cellStyle: params => {
          if (params.rowIndex % 2 == 0) {
            return { fontSize: '20px', backgroundColor: '#E8F5E9' }
          }
          return { fontSize: '20px', backgroundColor: '#A5D6A7' }
        },
      }
    ]
  }
]

export const RevenueModalColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Region",
    field: 'region',
    autoHeight: true
  },
  {
    headerName: "Actual Revenue(in lakhs)",
    field: 'revenue.actual',
    autoHeight: true
  },
  {
    headerName: "Sales Person",
    field: 'salesPersonName',
    autoHeight: true
  },
  {
    headerName: 'Actions',
    field: 'actions',
    cellRenderer: 'deleteButtonRenderer',
    editable: false,
    sortable: false,
    filter: false,
  }
]

export const RepeatPurchaseColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Region",
    field: "region",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "District",
    field: "district",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "State",
    field: "state",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Repeat Purchase",
    field: "count",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Total Users",
    field: "totalUser",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Conversion(%)",
    field: "conversion",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
]
export const RefferalColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "District",
    field: "district",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "State",
    field: "state",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Referrer Count",
    field: "uniqueReferrer",
    autoHeight: true,
    filter: "agTextColumnFilter",
    valueGetter: (params) => params.data.uniqueReferrer.length
  },
  {
    headerName: "Referrer",
    field: "uniqueReferrer",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Total Referrals",
    field: "totalReferrals",
    autoHeight: true,
    filter: "agTextColumnFilter",
  },
]

export const DhartiChatsColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: "Expiry",
    field: "expiry",
    autoHeight: true,
    sortable: true,
    width: 150,
    valueFormatter: (params) => {
      if (params.value) {
        return dayjs(params.value).format('DD MMM YYYY');
      }
      return '';
    }
  },
  {
    headerName: "Farmer Id",
    field: "farmer_id",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Farmer Name",
    field: "farmer_name",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Plots",
    field: "farmer_plot_ids",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 250
  },
  {
    headerName: "Roles",
    field: "roles",
    autoHeight: true,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Chat Length",
    field: "messages",
    autoHeight: true,
    sortable: true,
    width: 120,
    filter: "agTextColumnFilter",
    valueFormatter: (params) => {
      if (params.value) {
        return params.value.length
      }
      return 0;
    }
  },
];
