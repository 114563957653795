import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Box, IconButton } from "@mui/material";
import InformationText from "./InformationText"; // Adjust the import path if needed
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

interface Props {
  data: Record<string, string[]>;
  text: string;
  lastUpdated?: boolean;
}

const ScrollableSearchableCard: React.FC<Props> = ({
  data,
  text,
  lastUpdated,
}: Props): JSX.Element => {
  const [plotIds, setPlotIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredKeys, setFilteredKeys] = useState<string[]>(Object.keys(data));
  const [open,setOpen]=useState(false)

  useEffect(() => {
    // Flatten the plotIds arrays
    const allPlotIds: string[] = [];
    Object.keys(data).forEach((key) => {
      allPlotIds.push(...data[key]);
    });
    setPlotIds(allPlotIds);
  }, [data]);

  useEffect(() => {
    // Filter keys based on search term
    const keys = Object.keys(data).filter((key) =>
      key.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredKeys(keys);
  }, [searchTerm, data]);

  const toggleCard = (e: React.MouseEvent) => {
    e.stopPropagation();  
    setOpen((prev) => !prev); 
  };
  

  return (
    <div
  className={`card ${open ? 'open' : ''}`}
  style={{
    boxShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    padding: '16px', 
    marginBottom: '16px',  
    borderRadius: '12px',  
    display: 'flex',
    flexDirection: 'column',
    minHeight: open ? '230px' : '',
    maxHeight: open ? '230px' : '100px',
    overflowY: 'auto',
    transition: 'max-height 0.3s ease-out, padding-bottom 0.3s ease-out',
  }}
>
  <div
    className="card-header"
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <span
      className="card-title"
      style={{
        letterSpacing: '0.8px',
        textTransform: 'capitalize',
        fontSize: '20px',
        fontWeight:"400",
        color: '#333',
      }}
    >
      {text}
    </span>
    <button
      className="toggle-button"
      onClick={toggleCard}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
    </button>
  </div>

  {open && (
    <div
      className="search-container"
      style={{
        paddingTop: '0',
        paddingLeft: '5px',
        paddingRight: '5px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <input
        type="text"
        className="search-input"
        placeholder="Search"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        onClick={(e) => e.stopPropagation()}
        style={{
          width: '100%',
          padding: '8px',
          margin:"10px 0px",
          borderRadius: '65px',
          fontSize: '14px',
          border: '1px solid #ccc',
        }}
      />
    </div>
  )}

  {open && (
    <div
      className="content"
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
      }}
    >
      <div className="content-box" style={{ flexGrow: 1 }}>
        {filteredKeys.map((key: any) => (
          <InformationText data={data[key]} text={key} key={key} />
        ))}
      </div>
    </div>
  )}

  {lastUpdated && (
    <div
      className="last-updated"
      style={{
        marginTop: '8px',
      }}
    >
      <span
        className="last-updated-text"
        style={{
          fontSize: '12px',
          color: 'gray',
        }}
      >
        * Since last 7 days
      </span>
    </div>
  )}
</div>

  );
};

export default ScrollableSearchableCard;
