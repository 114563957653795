import React, { useEffect, useRef, useState } from 'react';
import { DefaultDropdownState, DropDownContainerProps, DropdownProps, DropdownState, FilterProps, RenderDropdownArgs } from '../types';
import { Option } from 'src/constants/interfaces';
import Header from '../Header';
import {
  getCropDiseases,
  getCropPests,
  getCropStages,
  getCropVarieties,
  getCropOptions as getCrops
} from 'src/services/crop.service';
import { generateOptions, sortByKey } from 'src/utils/helper';
import Grid from '@mui/material/Grid';
import Label from '../Label';
import Select, { SingleValue } from "react-select";
import { deleteButton, resetButton, submitButton } from '../action-buttons';
import { filterObservationTypes } from 'src/services/observationType.service';

const CropFilter: React.FC<FilterProps> = (props) => {
  const {
    actionButtonType,
    defaultBackgroundColor,
    defaultValue,
    dropdowns,
    errorBackgroundColor,
    filterId,
    hasDeleteButton,
    hasResetButton,
    hasSubmitButton,
    hideTitle,
    submittedBackgroundColor,
    onChange,
    onDelete,
    onReset,
    onSubmit,
    isOldId=false
  } = props;

  const [crops, setCrops] = useState<DropdownState>();
  const [cropVariety, setCropVariety] = useState<DropdownState>();
  const [cropStage, setCropStage] = useState<DropdownState>();
  const [disease, setDisease] = useState<DropdownState>();
  const [pest, setPest] = useState<DropdownState>();
  const [diseasePests, setDiseasePests] = useState<DropdownState>();
  const [observationType, setObservationType] = useState<DropdownState>();

  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  const showVarietyDropdown = dropdowns?.includes("crop-variety");
  const showStageDropdown = dropdowns?.includes("crop-stage");
  const showDiseaseDropdown = dropdowns?.includes("disease");
  const showPestDropdown = dropdowns?.includes("pest");
  const showDiseasePestDropdown = dropdowns?.includes("disease-pest-merged");
  const showObservationTypeDropdown = dropdowns?.includes("observation-types");

  const defaultSelected = useRef<any>();

  // initial api call
  useEffect(() => {
    defaultSelected.current = defaultValue;
    getCropOptions();
  }, []) // eslint-disable-line


  useEffect(() => {
    if (!!disease?.options && disease?.options?.length > 0) {
      handleDefaultDisease(disease?.options ?? []);
    }
  }, [disease?.options, diseasePests?.options]) // eslint-disable-line

  useEffect(() => {
    if (!!pest?.options && pest?.options?.length > 0) {
      handleDefaultPest(pest?.options ?? []);
    }
  }, [pest?.options, diseasePests?.options]) // eslint-disable-line

  useEffect(() => {
    if (!!observationType?.options && observationType.options.length > 0) {
      handleDefaultObservationType(observationType.options);
    }
  }, [observationType?.options]) // eslint-disable-line

  useEffect(() => {
    const updatedValue: CropPayload = generatePayload();

    if (!!onChange) {
      onChange(updatedValue, filterId ?? "");
    }
  }, [crops?.selected, cropVariety?.selected, cropStage?.selected, disease?.selected, pest?.selected, diseasePests?.selected, observationType?.selected]) // eslint-disable-line

  const generatePayload = (): CropPayload => {
    const updatedValue = {} as CropPayload;
    if (!!crops?.selected) {
      updatedValue.id = crops.selected.value as string;
    }

    if (!!cropVariety?.selected) {
      updatedValue.variety = cropVariety.selected.value as string;
    }

    if (!!cropStage?.selected) {
      updatedValue.stage = cropStage.selected.value as string;
    }

    if (!!disease?.selected) {
      updatedValue.diseasePest = disease.selected.value as string;
      updatedValue.category = "disease";
    }

    if (!!pest?.selected) {
      updatedValue.diseasePest = pest.selected.value as string;
      updatedValue.category = "pest";
    }

    if (!!observationType?.selected) {
      updatedValue.observationTypeId = observationType.selected.value as string;
    }

    if (!!diseasePests?.selected) {
      const selected = diseasePests.selected.value as string;

      // assuming disease and pest don't have any entry with save 'value'
      const isDisease = disease?.options?.filter(option => option.value === selected).length === 1;

      updatedValue.diseasePest = selected;
      updatedValue.category = isDisease ? "disease" : "pest";
    }

    return updatedValue;
  }

  // api calls
  const getCropOptions = () => {
    setCrops({ isLoading: true, options: [], selected: null, error: null });

    getCrops()
      .then(res => {
        const options = generateOptions(res, !!isOldId?"cropId":"id", "cropName");
        setCrops(crops => ({
          ...crops,
          options
        }))

        handleDefaultCrop(options);
      })
      .catch((error) => {
        setCrops(crops => ({
          ...crops,
          error
        }));
      })
      .finally(() => {
        setCrops((crops) => ({
          ...crops,
          isLoading: false
        }));
      })
  }

  const fetchCropStages = (cropId: string) => {
    setCropStage({
      isLoading: true,
      selected: null,
    });

    getCropStages(cropId)
      .then((res: any[]) => {
        const sortedStages = sortByKey(res, 'order')
        setCropStage((stage) => ({
          ...stage,
          options: generateOptions(sortedStages, "stageId", "name"),
        }))
      })
      .catch(error => {
        setCropStage((stage) => ({
          ...stage,
          error: error
        }))
      })
      .finally(() => {
        setCropStage((stage) => ({
          ...stage,
          isLoading: false,
        }));
      })
  }

  const fetchCropVarieties = (cropId: string) => {
    setCropVariety({
      isLoading: true,
      selected: null,
    });

    getCropVarieties(cropId)
      .then((res) => {
        setCropVariety((variety) => ({
          ...variety,
          options: generateOptions(res, "id", "name"),
        }))
      })
      .catch(error => {
        setCropVariety((variety) => ({
          ...variety,
          error
        }))
      })
      .finally(() => {
        setCropVariety((variety) => ({
          ...variety,
          isLoading: false,
        }));
      })
  }

  const fetchObservationTypes = (cropId: string, diseasePestId: string) => {
    setObservationType({ isLoading: true, options: [], error: null, selected: null });

    filterObservationTypes(
      { cropId, diseasePestId },
      { id: true, name: true }
    )
      .then(res => {
        const options = generateOptions(res, "id", "name");
        setObservationType(data => ({ ...data, options }));
      })
      .catch(err => {
        setObservationType(data => ({ ...data, error: err }));
      })
      .finally(() => setObservationType(data => ({ ...data, isLoading: false })));
  }

  // dropdown handlers  
  const handleDefaultCrop = (options: Option[]) => {
    const defaultCropId = defaultSelected.current?.cropId;
    if (defaultCropId) {
      const defaultSelected = options.filter(option => option.value === defaultCropId);
      if (defaultSelected.length !== 1) {
        console.warn(`Can't apply prefill for Crop ${defaultCropId} ${defaultSelected.length}`);
      } else {
        setCrops(crops => ({
          ...crops,
          selected: defaultSelected[0]
        }));

        handleDropdownChange("crop", defaultSelected[0]);
      }
    }
  }

  const handleDefaultDisease = (options: Option[]) => {
    const defaultDiseaseId = defaultSelected.current?.diseasePestId;
    let isDisease = defaultSelected.current?.isDisease;

    if ((defaultDiseaseId && isDisease) || isDisease === undefined) {
      const defaultSelected = options.filter(option => option.value === defaultDiseaseId);
      if (defaultSelected.length !== 1) {
        console.warn(`Can't apply prefill for Disease ${defaultDiseaseId} ${defaultSelected.length}`);
      } else {
        setDisease(disease => ({
          ...disease,
          selected: defaultSelected[0]
        }));
        setDiseasePests(diseasePests => ({
          ...diseasePests,
          selected: defaultSelected[0]
        }));
      }
    }
  }

  const handleDefaultPest = (options: Option[]) => {
    const defaultPestId = defaultSelected.current?.diseasePestId;
    const isPest = defaultSelected.current?.isPest;

    if ((defaultPestId && isPest) || isPest === undefined) {
      const defaultSelected = options.filter(option => option.value === defaultPestId);

      if (defaultSelected.length !== 1) {
        console.warn(`Can't apply prefill for Pest ${defaultPestId} ${defaultSelected.length}`);
      } else {
        setPest(pest => ({
          ...pest,
          selected: defaultSelected[0]
        }));
        setDiseasePests(diseasePests => ({
          ...diseasePests,
          selected: defaultSelected[0]
        }));
      }
    }
  }

  const handleDefaultObservationType = (options: Option[]) => {
    const defaultObservationTypeId = defaultSelected.current.observationTypeId;

    if (defaultObservationTypeId) {
      const defaultSelected = options.filter(option => option.value === defaultObservationTypeId);
      if (defaultSelected.length !== 1) {
        console.warn(`Can't apply prefill for Crop ${defaultObservationTypeId} ${defaultSelected.length}`);
      } else {
        setObservationType(crops => ({
          ...crops,
          selected: defaultSelected[0]
        }));

        handleDropdownChange("observation-types", defaultSelected[0]);
      }
    }
  }

  const handleDropdownChange = (
    dropdown: Dropdown,
    selected: SingleValue<Option>
  ) => {
    const value = selected?.value;
    switch (dropdown) {
      case "crop":
        if (value) handleCropChange(selected);
        else unselectOptions(['crop', 'variety', 'stage']);
        break;
      case "stage":
        if (value) handleStageChange(selected);
        else unselectOptions(['stage']);
        break;
      case "variety":
        if (value) handleVarietyChange(selected);
        else unselectOptions(['variety']);
        break;
      case "disease":
        if (value) handleDiseaseChange(selected);
        else unselectOptions(['disease']);
        break;
      case "pest":
        if (value) handlePestChange(selected);
        else unselectOptions(['pest']);
        break;
      case "disease-pest":
        if (value) handleDiseasePestChange(selected);
        else unselectOptions(['disease-pest', 'observation-types']);
        break;
      case "observation-types":
        if (value) handleObservationTypeChange(selected);
        else unselectOptions(['observation-types']);
        break;
    }
  }

  const handleCropChange = (selected: SingleValue<Option>) => {
    const value = selected?.value as string;
    if (!value) return;

    setCrops((crops) => ({
      ...crops,
      selected
    }))

    showStageDropdown && fetchCropStages(value);
    showVarietyDropdown && fetchCropVarieties(value);
    (showDiseaseDropdown || showPestDropdown || showDiseasePestDropdown) && fetchDisesasePests(value);
  }

  const fetchDisesasePests = async (cropId: string) => {
    setDiseasePests({ isLoading: true, selected: null, options: [] });

    try {
      const diseasesPromise = getCropDiseases(cropId);
      const pestsPromise = getCropPests(cropId);

      Promise.allSettled([diseasesPromise, pestsPromise]).then(
        ([diseasesResponse, pestsResponse]: any) => {

          const diseases = diseasesResponse.value;
          const pests = pestsResponse.value;

          // disease and pest options outside conditional statements
          // because we'll need them to tell whether selected diseasePest is disease or pest for diseasePest dropdown
          const pestOptions = getOptions([...pests]);
          setPest({
            isLoading: false,
            selected: null,
            options: pestOptions
          })

          const diseaseOptions = getOptions([...diseases]);
          setDisease({
            isLoading: false,
            selected: null,
            options: diseaseOptions
          })

          if (showDiseasePestDropdown) {
            const options = getOptions([...diseases, ...pests]);
            setDiseasePests({
              isLoading: false,
              selected: null,
              options,
            });
          }
        }
      );
    } catch (error) {
      console.error("fetch  diseases and pests", error);
      setDiseasePests(DefaultDropdownState);
    }
  };

  const handleStageChange = (selected: SingleValue<Option>) => {
    setCropStage(stage => ({ ...stage, selected }));
  }

  const handleVarietyChange = (selected: SingleValue<Option>) => {
    setCropVariety(variety => ({ ...variety, selected }));
  }

  const handleDiseaseChange = (selected: SingleValue<Option>) => {
    setDisease(disease => ({ ...disease, selected }));
  }
  const handlePestChange = (selected: SingleValue<Option>) => {
    setPest(pest => ({ ...pest, selected }));
  }
  const handleDiseasePestChange = (selected: SingleValue<Option>) => {
    setDiseasePests(diseasePests => ({ ...diseasePests, selected }));
    fetchObservationTypes(crops?.selected?.value as string, selected?.value as string);
  }

  const handleObservationTypeChange = (selected: SingleValue<Option>) => {
    setObservationType(observationType => ({ ...observationType, selected }));
  }

  const unselectOptions = (toUnselect: Dropdown[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === 'crop') {
        setCrops((state) => ({ ...state, selected: null }));
        cnt++;
      } else if (field === 'stage') {
        setCropStage(state => ({ ...state, selected: null }));
        if (cnt > 0) setCropStage(state => ({ ...state, options: [], selected: null }));
      } else if (field === "variety") {
        setCropVariety(state => ({ ...state, selected: null }));
        if (cnt > 0) setCropVariety(state => ({ ...state, options: [], selected: null }));
      } else if (field === "pest") {
        setPest(state => ({ ...state, selected: null }));
        if (cnt > 0) setPest(state => ({ ...state, options: [], selected: null }));
      } else if (field === "disease") {
        setDisease(state => ({ ...state, selected: null }));
        if (cnt > 0) setDisease(state => ({ ...state, options: [], selected: null }));
      } else if (field === "disease-pest") {
        setDiseasePests(state => ({ ...state, selected: null }));
        if (cnt > 0) setDiseasePests(state => ({ ...state, options: [], selected: null }));
      } else {
        setObservationType(state => ({ ...state, selected: null }));
        if (cnt > 0) setObservationType(state => ({ ...state, options: [], selected: null }));
      }
    }
  }


  // action button handlers
  const onFilterSubmit = () => {
    if (!crops?.selected) {
      alert("no crop selected");
      return;
    }

    const payload = generatePayload();

    setIsApplied(true);
    if (!!onSubmit) {
      onSubmit(payload, filterId ?? "");
    }
  }

  const onFilterReset = () => {
    if (!!onReset) {
      onReset(filterId ?? "");
    }

    // reset isApplied and error states
    setIsApplied(false);
    setHasError("");
  }

  const onFilterDelete = () => {
    setIsApplied(false);
    if (!!onDelete) {
      onDelete(filterId ?? "");
    }
  }

  /**
   * background colors for the filter
   */
  let backgroundColor = "#D6D6D6";
  if (defaultBackgroundColor) {
    backgroundColor = defaultBackgroundColor;
  }

  if (isApplied && submittedBackgroundColor) {
    backgroundColor = submittedBackgroundColor;
  }

  if (isApplied && !!hasError && errorBackgroundColor) {
    backgroundColor = errorBackgroundColor;
  }

  return (
    <Grid>
      {!hideTitle && <Header title='Crop' />}

      <Grid container justifyContent={"space-between"} alignItems={"center"} borderRadius={2} p={1} gap={1} style={{ backgroundColor }} >
        {renderDropdown({ 
          title: "Crop", 
          dropdownState: crops, 
          dropdownType: "crop",
          isDisabled: !!crops?.isLoading,
          handleDropdownChange
        })}

        {showVarietyDropdown && renderDropdown({ 
          title: "Crop Variety", 
          dropdownState: cropVariety, 
          dropdownType: "variety",
          isDisabled: !crops?.selected || !!cropVariety?.isLoading,
          handleDropdownChange
        })}

        {showStageDropdown && renderDropdown({ 
          title: "Crop Stage", 
          dropdownState: cropStage, 
          dropdownType: "stage",
          isDisabled: !crops?.selected || !!cropStage?.isLoading,
          handleDropdownChange
        })}

        {showDiseaseDropdown && renderDropdown({ 
          title: "Disease", 
          dropdownState: disease, 
          dropdownType: "disease" ,
          isDisabled: !crops?.selected || !!disease?.isLoading,
          handleDropdownChange
        })}

        {showPestDropdown && renderDropdown({ 
          title: "Pest", 
          dropdownState: pest, 
          dropdownType: "pest" ,
          isDisabled: !crops?.selected || !!pest?.isLoading,
          handleDropdownChange
        })}

        {showDiseasePestDropdown && renderDropdown({ 
          title: "Disease/Pest", 
          dropdownState: diseasePests, 
          dropdownType: "disease-pest" ,
          isDisabled: !crops?.selected || !!diseasePests?.isLoading,
          handleDropdownChange
        })}

        {showObservationTypeDropdown && renderDropdown({ 
          title: "Observation Type", 
          dropdownState: observationType, 
          dropdownType: "observation-types",
          isDisabled: !crops?.selected || !diseasePests?.selected || !!observationType?.isLoading,
          handleDropdownChange
        })}

        {/* action buttons */}
        <Grid item xs={2} mt={1} display={"flex"} justifyContent={"flex-end"} gap={"5px"} >
          {submitButton({ show: hasSubmitButton, onClick: onFilterSubmit, actionButtonType })}
          {resetButton({ show: hasResetButton, onClick: onFilterReset, actionButtonType })}
          {deleteButton({ show: hasDeleteButton, onClick: onFilterDelete, actionButtonType })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CropFilter;

const getOptions = (array: any[]) => {
  const options: Option[] = [...array].reduce(
    (acc, current) => {
      const existingItem = acc.find(
        (item: any) =>
          item.value === current.diseaseId || item.value === current.pestId
      );
      if (!existingItem) {
        // If the item is not already in the result array, add it
        const newItem: Option = {
          label: current.displayName,
          value: current.diseaseId || current.pestId || 0, // Use diseaseId or pestId as id
        };
        acc.push(newItem);
      }

      return acc;
    },
    []
  );

  return options;
}

/**
 * ================== Types ======================
 */



type Dropdown = "crop" | "variety" | "stage" | "disease" | "pest" | "disease-pest" | "observation-types";

export type DiseasePestCategory = "disease" | "pest";

export type CropPayload = {
  id: string,
  variety?: string,
  stage?: string,
  diseasePest?: string;
  category?: DiseasePestCategory;
  observationTypeId?: string;
};


const renderDropdown = ({
  title,
  dropdownState,
  dropdownType,
  isDisabled,
  handleDropdownChange
}: RenderDropdownArgs<Dropdown>) => (
  <Grid {...DropDownContainerProps}>
    <Label title={title} />
    <Select
      isLoading={dropdownState?.isLoading}
      isDisabled={isDisabled}
      value={dropdownState?.selected}
      options={dropdownState?.options ?? []}
      onChange={
        (selected: SingleValue<Option>) => handleDropdownChange(dropdownType, selected)
      }
      {...DropdownProps}
    />
  </Grid>
)