import { DatePicker } from "src/components";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import {
  getCropOptions,
  getCropDiseases,
  getCropPests,
  getPestPrediction,
  getDiseasePrediction,
} from "src/services/crop.service";
import {
  Collapsibles,
  CropDetails,
  getDiseasePestColor,
} from "../sections/FilterSection";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import dayjs from "dayjs";
import { diseasePestRanges } from "../constant";
import { GraphType } from "..";
import { generateOptions } from "src/utils/helper";

type Props = {
  buttonDisabled: any;
  setButtonDisabled: any;
  isVisible: boolean;
  closeRestCollapsiblesExcept: (x: Collapsibles) => void;
  setMapsDate: (x: any) => void;
  setIndexData: (x: any) => void;
  setCropData: (x: any) => void;
  handleFilterUpdate: (x: any) => void;
  setGraphType: (x: GraphType) => void;
  setGraphSubType: (x: string) => void;
};

export const DropDownStyles = {
  container: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: "100%",
    marginLeft: "5px",
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderTop: state.isFocused ? "1px" : "0px",
    borderLeft: state.isFocused ? "1px" : "0px",
    borderRight: state.isFocused ? "1px" : "0px",
  }),
  menu: (baseStyles: any, state: any) => ({
    ...baseStyles,
    zIndex: 100,
  }),
};

export const DropDownProps = {
  styles: DropDownStyles,
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
};

export const DiseaseGridProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: "1px",
};

const CropFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    buttonDisabled,
    isVisible,
    closeRestCollapsiblesExcept,
    setMapsDate,
    setIndexData,
    setCropData,
    handleFilterUpdate,
    setGraphType,
    setGraphSubType
  } = props;

  // diesase
  const [cropDetails, setCropDetails] = useState<CropDetails>({
    name: "",
    disease: "",
    pest: "",
  });
  const todaysDate = new Date();
  const [diseaseDate, setDiseaseDate] = useState<Date | null>(
    dayjs(todaysDate).add(-1, "day").toDate()
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // drodown options
  const [crops, setCrops] = useState<SingleValue<any>>([]);
  const [diseases, setDiseases] = useState<SingleValue<any>>([]);
  const [pests, setPests] = useState<SingleValue<any>>([]);

  // selected options
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null);
  const [selectedDiseasesOption, setSelectedDiseasesOption] =
    useState<any>(null);
  const [selectedPestsOption, setSelectedPestsOption] = useState<any>(null);

  // loading states
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [isDiseasesLoading, setIsDiseasesLoading] = useState<boolean>(false);
  const [isPestsLoading, setIsPestsLoading] = useState<boolean>(false);

  // submit button
  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const date = useRef<any>(null);
  const lastDate = dayjs(new Date()).add(-1, "day").format("DD/MM/YYYY");

  const dateHandler = (newDate: Date | null): void => {
    if (isSubmitButtonDisabled && !!newDate && !!selectedCropOption) {
      setErrorMessage("Kindly select required options");
    }
    setDiseaseDate(newDate);
  };

  const handleCropDataChange = (updatedCropDetails: any): void => {
    setCropDetails(updatedCropDetails);
  };

  useEffect(() => {
    if(isVisible){
      getCropOptions().then((res) => {
        setCrops(generateOptions(res, "id", "cropName"));
        setIsCropLoading(false);
      });
    }
  }, [isVisible]);

  useEffect(() => {
    date.current = diseaseDate;
    if (!isSubmitButtonDisabled) {
      showResults();
    }
  }, [diseaseDate]);

  // update(+1) date in every 5 seconds
  let interval: any = null;
  useEffect(() => {
    if (isAutoPlay) {
      startInterval();
    } else {
      clearInterval(interval);
      interval = null;
    }
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [isAutoPlay]);

  const startInterval = () => {
    interval = setInterval(() => {
      if (dayjs(date.current).format("DD/MM/YYYY") === lastDate) {
        setIsAutoPlay(false);
      } else updateDate(1);
    }, 5000);
  };

  const handleCropChange = (selected: SingleValue<any>) => {
    setSelectedCropOption(selected);
    const { value } = selected;

    // handleCropDataChange((x: any) => { return { ...prevData, name: value } })
    handleCropDataChange((x: any) => {
      return { ...x, name: value };
    });

    setIsDiseasesLoading(true);
    setIsPestsLoading(true);
    unselectOptions(["pest", "disease"]);

    getCropDiseases(value).then((res) => {
      setDiseases(generateOptions(res, "diseaseId", "name"));
      setIsDiseasesLoading(false);
    });

    getCropPests(value).then((res) => {
      setPests(generateOptions(res, "pestId", "name"));
      setIsPestsLoading(false);
    });
  };

  const handleDiseaseChange = (selected: SingleValue<any>) => {
    setSelectedDiseasesOption(selected);
    handleCropDataChange((prevData: CropDetails) => {
      return { ...prevData, disease: selected.value, pest: "" };
    });
  };

  const handlePestChange = (selected: SingleValue<any>) => {
    setSelectedPestsOption(selected);
    handleCropDataChange((prevData: CropDetails) => {
      return { ...prevData, pest: selected.value, disease: "" };
    });
  };

  const handleDropdownChange = (
    dropdown: string,
    selected: SingleValue<any>
  ) => {
    const value = selected?.value;

    switch (dropdown) {
      case "crop":
        setIsSubmitButtonDisabled(true);
        if (value) handleCropChange(selected);
        else unselectOptions(["crop", "disease", "pest"]);
        break;
      case "disease":
        if (value) {
          setIsSubmitButtonDisabled(false);
          handleDiseaseChange(selected);
          unselectOptions(["pest"]);
          if (!!errorMessage) setErrorMessage(null);
        } else {
          unselectOptions(["disease"]);
          setIsSubmitButtonDisabled(true);
        }
        break;
      case "pest":
        if (value) {
          setIsSubmitButtonDisabled(false);
          handlePestChange(selected);
          unselectOptions(["disease"]);
          if (!!errorMessage) setErrorMessage(null);
        } else {
          unselectOptions(["pest"]);
          setIsSubmitButtonDisabled(true);
        }
        break;
      default:
        break;
    }
  };

  const unselectOptions = (toUnselect: string[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === "crop") {
        setSelectedCropOption(null);
        handleCropDataChange((prevData: CropDetails) => {
          return { ...prevData, name: "" };
        });
        cnt++;
      } else if (field === "disease") {
        setSelectedDiseasesOption(null);
        handleCropDataChange((prevData: CropDetails) => {
          return { ...prevData, disease: "" };
        });
        if (cnt++ > 0) setDiseases([]);
      } else {
        setSelectedPestsOption(null);
        handleCropDataChange((prevData: CropDetails) => {
          return { ...prevData, pest: "" };
        });
        if (cnt++ > 0) setPests([]);
      }
    }
  };

  const updateDate = (value: number) => {
    if (new Date() === new Date(date.current as any)) {
      setIsAutoPlay(false);
      return;
    }
    let updatedDate = dayjs(date.current).add(value, "day").toDate();
    setDiseaseDate(updatedDate);
  };

  const showResults = () => {
    setMapsDate(dayjs(diseaseDate).format("DD/MM/YYYY").toString());
    const { disease, name, pest } = cropDetails;

    if (disease.length === 0 && name.length > 0 && pest.length > 0) {
      setGraphType('pest')
      setGraphSubType(pest)
      getPestPrediction(name, pest, dayjs(diseaseDate).format('YYYY-MM-DD').toString()).then((res) => {
        if (res.length > 0) {
          setCropData(
            res.map((val: any) => ({
              ...val,
              color: getDiseasePestColor(val.riskLevel),
              value: val.probability,
            }))
          );
          setIndexData(diseasePestRanges);
        } else {
          setCropData([]);
        }
      });
    }
    if (disease.length > 0 && name.length > 0 && pest.length === 0) {
      setGraphType('disease')
      setGraphSubType(disease)
      getDiseasePrediction(name, disease, dayjs(diseaseDate).format('YYYY-MM-DD').toString()).then((res) => {
        if (res.length > 0) {
          setCropData(
            res.map((val: any) => ({
              ...val,
              color: getDiseasePestColor(val.riskLevel),
              value: val.probability,
            }))
          );
          setIndexData(diseasePestRanges);
        } else {
          setCropData([]);
        }
      });
    }
    handleFilterUpdate("crop");
  };

  return (
    <Grid my={3} boxShadow={2} borderRadius={3} px={2}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        data-testid="dashboard-Pest"
      >
        <Typography
          letterSpacing={0.8}
          variant="overline"
          textTransform={"capitalize"}
          fontSize={20}

        >
          Pest/Disease
        </Typography>
        <IconButton
          onClick={() => closeRestCollapsiblesExcept(Collapsibles.disease)}
        >
          {isVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      {isVisible && (
        <Grid pb={2}>
          <Grid px={2} mb={2}>
            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={3}>
                Crop
              </Typography>
              <Select
                options={crops}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("crop", selected)
                }
                isLoading={isCropLoading}
                isDisabled={isCropLoading}
                value={selectedCropOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1">Disease</Typography>
              <Select
                options={diseases}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("disease", selected)
                }
                isLoading={isDiseasesLoading}
                isDisabled={!selectedCropOption || isDiseasesLoading}
                value={selectedDiseasesOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={3.23}>
                Pest
              </Typography>
              <Select
                options={pests}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("pest", selected)
                }
                isLoading={isPestsLoading}
                isDisabled={!selectedCropOption || isPestsLoading}
                value={selectedPestsOption}
                {...DropDownProps}
              />
            </Grid>
          </Grid>
          <Grid>
            <DatePicker
              onChange={dateHandler}
              value={diseaseDate}
              hasAutoplay
              hasDateChangeIcon
            />
          </Grid>

          <Grid>
            <Button
              variant="outlined"
              fullWidth
              style={{ borderRadius: "20px" }}
              size="large"
              color="success"
              disabled={isSubmitButtonDisabled}
              onClick={showResults}
            >
              Show Results
            </Button>
          </Grid>

          {!!errorMessage && (
            <Grid mt={1}>
              <Typography
                ml={1}
                variant="caption"
                fontSize={10}
                color={"red"}
                fontWeight={"bold"}
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default memo(CropFilter);
