import { CORE_API } from "src/constants/api"
import { getData, postData } from "./common.service"

export async function getPlans() {
    return getData('/plans', {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    })
}

export async function getRecharges(planId: string) {
    return getData(`/plans/${planId}/recharges`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    })
}

export async function getPayments(fromDate: Date, endDate: Date) {
    return getData(`/payments?filter=` + JSON.stringify({
        order: 'createdAt DESC',
        where: {
            
            and: [{ createdAt: { lte: endDate } }, { createdAt: { gte: fromDate } }],
        },
    }), {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    })
}

export async function getPlotPlan(plotId: string) {
    return getData(`/plot-plans/${plotId}`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    })
}

export async function authorizePayment(id: string) {
    return getData(`/payments/${id}/authorize`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
    })
}

export async function updatePlotPlan(plotPlanData: any, currentPlotPlan: any) {
    const url = currentPlotPlan ? `/plot-plans/${currentPlotPlan.plotId}` : '/plot-plans';
    return postData(url, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: plotPlanData,
        usePutMethod: currentPlotPlan ? true : false
    })
}

export async function createVouchers(vouchers: any[]) {
    return postData('/vouchers', {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: vouchers
    })
}

export async function activateVoucher(voucherId: string) {
    return getData(`/vouchers/${voucherId}/activate`, {
        basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
        sendTokenInHeader: true
    })
}

export async function fetchVoucherInsights({
    startDate, endDate
}: VoucherInsightArgs): Promise<VoucherInsight[]> {
    const url = '/daily-recharge?startTime={startDate}&endTime={endDate}';
    const updatedUrl = url
        .replace('{startDate}', startDate)
        .replace('{endDate}', endDate!)

    return getData(updatedUrl, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}

type VoucherInsightArgs = {
    startDate: string,
    endDate?: string,
}

export type VoucherInsight = {
    "createdAt": string,
    "userId": string,
    "plotId": string,
    "status": string,
    "rechargeId": string,
    "rechargeType": string,
    "farmerName": string,
    "mobile": string,
    "deviceType": string,
    "regionName": string,
    "district": string,
    "state": string;
}

// Fetch payments settlements for a specific date
export async function getSettlementsByDate(year: number, month: number, day: number): Promise<any> {
    const url = `/settlements?year=${year}&month=${month}&day=${day}`;
    return getData(url, {
      basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
      sendTokenInHeader: true,
    });
  }
  
  // Fetch payment details by entity IDs for Settlements
  export async function getPaymentDetailsByIds(paymentIds: string[]): Promise<any> {
    const filter = encodeURIComponent(
      JSON.stringify({
        where: { paymentId: { inq: paymentIds } },
      })
    );
  
    const url = `/payments?filter=${filter}`;
    return getData(url, {
      basePath: process.env.REACT_APP_PAYMENT_API_BASEPATH,
      sendTokenInHeader: true,
    });
  }